import {
  ADD_RECENT_RECORD,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST,
  SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST,
  SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_JOBS_REQUEST,
  SAFETY_NET_EVERYWHERE_JOBS_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_JOBS_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST,
  SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_SUCCESS,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS,
  SAFETY_NET_UPLOAD_CSV_FILE_REQUEST,
  SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_FAIL,
  SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_SUCCESS,
} from '../Constants/safetyNetConstants';

import AxiosApi from '../../config/axios-api';
import axios from 'axios';

const safetyNetMailboxChartDataAction = (mailboxId) => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST, payload: mailboxId });
    const { data } = await AxiosApi.get('safety-net/mailbox-chart-data/' + mailboxId);
    dispatch({
      type: SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS,
      payload: data.result.data,
    });
  } catch (error) {
    dispatch({ type: SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL, payload: error.message });
  }
};

const safetyNetMailboxTimelineDataAction = (mailboxId) => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST, payload: mailboxId });
    const { data } = await AxiosApi.get('safety-net/mailbox-timeline-data/' + mailboxId);
    dispatch({
      type: SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS,
      payload: data.result.data,
    });
  } catch (error) {
    dispatch({
      type: SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

const safetyNetAllMailboxesChartDataAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST, payload: companyId });
    const { data } = await AxiosApi.get('safety-net/all-company-mailboxes-chart-data/' + companyId);
    dispatch({
      type: SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS,
      payload: data.result.data,
    });
  } catch (error) {
    dispatch({ type: SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL, payload: error.message });
  }
};

const safetyNetAllMailboxesTimelineDataAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST, payload: companyId });
    const { data } = await AxiosApi.get(
      'safety-net/all-company-mailboxes-timeline-data/' + companyId,
    );
    dispatch({
      type: SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS,
      payload: data.result.data,
    });
  } catch (error) {
    dispatch({ type: SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL, payload: error.message });
  }
};

const uploadCsvFileAction =
  (csvFile, filename, selectedEmailColumn, hasHeaders) => async (dispatch) => {
    try {
      dispatch({ type: SAFETY_NET_UPLOAD_CSV_FILE_REQUEST, payload: null });

      // First request to get the signed url
      const { data: signedUrlData } = await AxiosApi.post('safety-net/generate-upload-url', {
        filename,
        selectedEmailColumn,
        hasHeaders,
      });

      const signedUrl = signedUrlData.result.signedUrl;

      if (!signedUrl) {
        throw new Error('Failed to get signed URL from server');
      }

      // Upload the file to S3 with the signed URL
      const uploadResult = await axios.put(signedUrl, csvFile, {
        headers: {
          'Content-Type': 'text/csv',
        },
      });

      dispatch({ type: SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_SUCCESS });

      // Return the result if there's need to use it after this action is dispatched
      return uploadResult;
    } catch (error) {
      dispatch({ type: SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_FAIL, payload: error.message });
      throw error;
    }
  };

const getSafetyNetEverywhereJobsAction = () => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_EVERYWHERE_JOBS_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/manual-validation-jobs');
    dispatch({ type: SAFETY_NET_EVERYWHERE_JOBS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: SAFETY_NET_EVERYWHERE_JOBS_REQUEST_FAIL, payload: error.message });
  }
};

const getSafetyNetEverywhereCsvFileAction = (jobId) => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST });
    const { data } = await AxiosApi.get(`safety-net/manual-validation-records/${jobId}`);
    const filename = Object.keys(data.result)[0];
    const fileData = data.result[filename];

    dispatch({
      type: SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_SUCCESS,
      payload: { filename, fileData },
    });
  } catch (error) {
    dispatch({ type: SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_FAIL, payload: error.message });
  }
};

const getSafetyNetEverywhereStatsAndDataAction = () => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/stats-and-data');

    dispatch({ type: SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_FAIL, payload: error.message });
  }
};

const getSafetyNetEverywhereNewProcessedRecordsAction = (lastValidatedAt) => async (dispatch) => {
  try {
    const { data } = await AxiosApi.get('safety-net/new-processed-records', {
      params: { lastValidatedAt },
    });
    return data.result;
  } catch (error) {
    console.error('Error in fetching Safety Net Everywhere jobs:', error);
    throw error;
  }
};

const getSafetyNetEverywhereValidationsByStatusAction =
  (status, filterType) => async (dispatch) => {
    try {
      dispatch({ type: SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST, payload: null });
      const { data } = await AxiosApi.get(
        `safety-net/manual-validation-records-by-status/${status}`,
        {
          params: { filterType },
        },
      );

      dispatch({
        type: SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

const getSafetyNetEverywhereValidationForJobAction =
  (jobId, requestedStatus) => async (dispatch) => {
    try {
      dispatch({ type: SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST, payload: null });
      const { data } = await AxiosApi.get('safety-net/manual-validation-records/' + jobId, {
        params: { status: requestedStatus },
      });

      dispatch({
        type: SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

const getSafetyNetEverywhereCsvDataAction = (status, downloadType) => async (dispatch) => {
  try {
    const { data } = await AxiosApi.get('safety-net/request-csv-data', {
      params: { status, download_type: downloadType },
    });
    return data.result;
  } catch (error) {
    console.error('Error in fetching Safety Net Everywhere jobs:', error);
    throw error;
  }
};

const getMostRecentCsvAction = (status) => async (dispatch) => {
  try {
    const { data } = await AxiosApi.get('safety-net/most-recent-csv', { params: { status } });
    return data.result;
  } catch (error) {
    console.error('Error in fetching Safety Net Everywhere jobs:', error);
    throw error;
  }
};

const getRemainingSafetyNetCreditsAction = () => async (dispatch) => {
  try {
    dispatch({ type: SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/remaining-credits');

    dispatch({ type: SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_FAIL, payload: error.message });
  }
};

const sendSNEInsufficientCreditsNotification = async (creditsRemaining, creditsRequired) => {
  try {
    const requestBody = {
      creditsRemaining,
      creditsRequired,
    };
    await AxiosApi.post('safety-net/insufficient-credits', requestBody);
  } catch (error) {
    console.error('Error in sending insufficient credits notification:', error);
    throw error;
  }
};

const updateSNECreditsUsedAction = async (creditsUsed) => {
  try {
    const requestBody = {
      creditsUsed,
    };
    const result = await AxiosApi.post('safety-net/update-credits-used', requestBody);
    return result.status;
  } catch (error) {
    console.error('Error in updating credits used:', error);
    throw error;
  }
};

const pollForExportJobCompletedAction = (exportJobId) => async (dispatch) => {
  try {
    const { data } = await AxiosApi.get('safety-net/poll-export-job', { params: { exportJobId } });
    return data.result;
  } catch (error) {
    console.error('Error in fetching Safety Net Everywhere jobs:', error);
    throw error;
  }
};

const getSafetyNetPreventedEmailDataAction =
  (startDate, endDate, mailboxId) => async (dispatch) => {
    try {
      const { data } = await AxiosApi.get('safety-net/export-prevented-emails/', {
        params: { startDate, endDate, mailboxId },
      });
      return data.result;
    } catch (error) {
      console.error('Error in fetching Safety Net Everywhere jobs:', error);
      throw error;
    }
  };

export {
  safetyNetMailboxChartDataAction,
  safetyNetMailboxTimelineDataAction,
  safetyNetAllMailboxesChartDataAction,
  safetyNetAllMailboxesTimelineDataAction,
  uploadCsvFileAction,
  getSafetyNetEverywhereJobsAction,
  getSafetyNetEverywhereCsvFileAction,
  getSafetyNetEverywhereStatsAndDataAction,
  getSafetyNetEverywhereNewProcessedRecordsAction,
  getSafetyNetEverywhereValidationsByStatusAction,
  getSafetyNetEverywhereCsvDataAction,
  getMostRecentCsvAction,
  getSafetyNetEverywhereValidationForJobAction,
  getRemainingSafetyNetCreditsAction,
  sendSNEInsufficientCreditsNotification,
  updateSNECreditsUsedAction,
  pollForExportJobCompletedAction,
  getSafetyNetPreventedEmailDataAction,
};
