export const SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST =
  'SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST';
export const SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL =
  'SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL';

export const SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST =
  'SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST';
export const SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL =
  'SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL';

export const SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST =
  'SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST';
export const SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL =
  'SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL';

export const SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST =
  'SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST';
export const SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL =
  'SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL';

export const SAFETY_NET_UPLOAD_CSV_FILE_REQUEST = 'SAFETY_NET_UPLOAD_CSV_FILE_REQUEST';
export const SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_SUCCESS =
  'SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_SUCCESS';
export const SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_FAIL = 'SAFETY_NET_UPLOAD_CSV_FILE_REQUEST_FAIL';

export const SAFETY_NET_EVERYWHERE_JOBS_REQUEST = 'SAFETY_NET_EVERYWHERE_JOBS_REQUEST';
export const SAFETY_NET_EVERYWHERE_JOBS_REQUEST_SUCCESS =
  'SAFETY_NET_EVERYWHERE_JOBS_REQUEST_SUCCESS';
export const SAFETY_NET_EVERYWHERE_JOBS_REQUEST_FAIL = 'SAFETY_NET_EVERYWHERE_JOBS_REQUEST_FAIL';

export const SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST = 'SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST';
export const SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_SUCCESS =
  'SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_SUCCESS';
export const SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_FAIL =
  'SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_FAIL';

export const SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST =
  'SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST';
export const SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_FAIL =
  'SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_FAIL';

export const SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST =
  'SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST';
export const SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_SUCCESS =
  'SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_SUCCESS';
export const SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_FAIL =
  'SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_FAIL';

export const SAFETY_NET_EVERYWHERE_GET_CSV_FILE_REQUEST =
  'SAFETY_NET_EVERYWHERE_GET_CSV_FILE_REQUEST';
export const SAFETY_NET_EVERYWHERE_GET_CSV_FILE_REQUEST_SUCCESS =
  'SAFETY_NET_EVERYWHERE_GET_CSV_FILE_REQUEST_SUCCESS';
export const SAFETY_NET_EVERYWHERE_GET_CSV_FILE_REQUEST_FAIL =
  'SAFETY_NET_EVERYWHERE_GET_CSV_FILE_REQUEST_FAIL';

export const SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST =
  'SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST';
export const SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_SUCCESS =
  'SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_SUCCESS';
export const SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_FAIL =
  'SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_FAIL';

export const SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST = 'SAFETY_NET_CREDITS_DATA_REQUEST';
export const SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_CREDITS_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_FAIL =
  'SAFETY_NET_CREDITS_DATA_REQUEST_FAIL';
