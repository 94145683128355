import {
  BarChartOutlined,
  BulbOutlined,
  ClockCircleOutlined,
  GlobalOutlined,
  GoldOutlined,
  LeftOutlined,
  LogoutOutlined,
  MailOutlined,
  SafetyOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Image, Layout, Menu, Progress, Tooltip, Typography } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  USER_CONTENT_TESTS,
  USER_CREATE_CONTENT_TESTS,
  USER_EDIT_CONTENT_TESTS,
  USER_INSIGHTS,
  USER_LEADERBOARD,
  USER_MAILBOXES,
  USER_SAFETY_NET,
  USER_SAFETY_NET_EVERYWHERE,
  USER_SENDING_PATTERN,
  USER_SETTINGS,
  USER_THROTTLING,
} from '../Routes/Constants/RouteUrls';
import {
  getCloseMailboxes,
  getOutreachMailboxes,
  getSalesloftMailboxes,
} from '../Store/Actions/integrationActions';
import {
  getRemainingSafetyNetCreditsAction,
  getSafetyNetEverywhereJobsAction,
} from '../Store/Actions/safetyNetActions';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { Auth } from 'aws-amplify';
import LabIcon from '../assets/images/sidebar/LabIcon';
import SafetyNetEverywhereCreditsUsage from '../Components/SafetyNetEverywhereComponents/SafetyNetEverywhereCreditsUsage';
import { getThrottlingMailboxes } from '../Store/Actions/throttlingActions';
import { getlocalStorageValue } from '../Helpers/globalValues';
import { hasPermission } from '../Helpers/hasPermission';
import { mailboxDomainListsAction } from '../Store/Actions/mailboxActions';
import { scrollToTop } from '../Helpers/WindowHelper';

const SiderLayout = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const { Text } = Typography;
  const [showSendingPattern, setShowSendingPattern] = useState(false);
  const [showSafetyNet, setShowSafetyNet] = useState(false);
  const [showThrottling, setShowThrottling] = useState(false);
  const [showSafetyNetEverywhere, setShowSafetyNetEverywhere] = useState(false);
  const { mailboxDomainLists } = useSelector((state) => state.mailboxDomainListsInfo);
  const [user, setUser] = useState(null);
  const userRole = localStorage.getItem('userRole');
  const isOwnerOrAdmin = userRole === 'owner' || userRole === 'admin';
  const canViewContentTests = hasPermission('viewContentTests');

  const ldClient = useLDClient();
  const featureFlags = useFlags();

  useEffect(() => {
    const fetchUser = async () => {
      const session = await Auth.currentSession();
      setUser(session.getIdToken().payload);
      const userDetails = session.getIdToken().payload;

      ldClient.identify({
        kind: 'multi',
        user: {
          key: `user-${userDetails['custom:user_id']}`,
          name: `${userDetails['custom:first_name']} ${userDetails['custom:last_name']}`,
          email: userDetails.email,
        },
        company: {
          key: `company-${userDetails['custom:company_id']}`,
        },
      });
    };

    fetchUser();
  }, []);

  const { outreachMailboxesApiData: outreachSafetyNetMailboxes } = useSelector(
    (state) => state.outreachMailboxesInfo,
  );

  const { closeMailboxesApiData: closeSafetyNetMailboxes } = useSelector(
    (state) => state.closeMailboxesInfo,
  );

  const { salesloftMailboxesApiData: salesloftSafetyNetMailboxes } = useSelector(
    (state) => state.salesloftMailboxesInfo,
  );

  const { throttlingMailboxesApiData: throttlingMailboxes } = useSelector(
    (state) => state.throttlingMailboxes,
  );

  const { data: safetyNetEverywhereJobs } = useSelector(
    (state) => state.safetyNetEverywhereJobsData,
  );
  const { data: safetyNetEverywhereCreditsData } = useSelector(
    (state) => state.safetyNetEverywhereCreditsData,
  );

  const { demoData, userId, companyId } = getlocalStorageValue(['demoData', 'userId', 'companyId']);
  const history = useHistory();

  const dispatch = useDispatch();
  const { SubMenu } = Menu;
  const sendingPattern = (type) => {
    if (type === 'open') {
      setShowSendingPattern(true);
      setOpenKeys(['subSendingPattern']);
      setloader(locationKey);
    } else {
      setShowSendingPattern(false);
    }
  };

  const safetyNet = (type) => {
    if (type === 'open') {
      setShowSafetyNet(true);
      setOpenKeys(['subSafetyNet']);
      setloader(locationKey);
    } else {
      history.push(USER_MAILBOXES);
      setShowSafetyNet(false);
    }
  };

  const throttling = (type) => {
    if (type === 'open') {
      setShowThrottling(true);
      setOpenKeys(['subThrottling']);
      setloader(locationKey);
    } else {
      history.push(USER_MAILBOXES);
      setShowThrottling(false);
    }
  };

  const safetyNetEverywhere = (type) => {
    if (type === 'open') {
      setShowSafetyNetEverywhere(true);
      setOpenKeys(['subSafetyNetEverywhere']);
      setloader(locationKey);
    } else {
      history.push(USER_MAILBOXES);
      setShowSafetyNetEverywhere(false);
    }
  };

  const handleLogout = async () => {
    window.heap.resetIdentity();
    localStorage.clear();
    await Auth.signOut({ global: true });
    history.push('/');
  };
  const [loader, setloader] = useState('1');
  const location = useLocation();
  let locationKey = '1';
  if (location) {
    switch (location.pathname) {
      case USER_MAILBOXES:
        locationKey = '1';
        break;
      case USER_LEADERBOARD:
        locationKey = '9';
        break;
      case USER_INSIGHTS:
        locationKey = '2';
        break;
      case USER_CONTENT_TESTS:
      case USER_EDIT_CONTENT_TESTS:
      case USER_CREATE_CONTENT_TESTS:
        locationKey = '5';
        break;
      case USER_SETTINGS:
        locationKey = '3';
        break;
      case USER_SAFETY_NET:
        locationKey = '-1';
        break;
      case USER_THROTTLING:
        locationKey = '-2';
        break;
      case USER_SAFETY_NET_EVERYWHERE:
        locationKey = '6';
        break;
      default:
        if (
          location.pathname.includes(USER_EDIT_CONTENT_TESTS) ||
          location.pathname.includes(USER_CREATE_CONTENT_TESTS)
        ) {
          locationKey = '5';
        }
        location?.pathname?.includes(USER_SENDING_PATTERN) &&
          (locationKey = location?.pathname?.split('/')[3]);
        location?.pathname?.includes(USER_SAFETY_NET) &&
          (locationKey = location?.pathname?.split('/')[3]);
        location?.pathname?.includes(USER_THROTTLING) &&
          (locationKey = location?.pathname?.split('/')[3]);
        location?.pathname?.includes(USER_SAFETY_NET_EVERYWHERE) &&
          (locationKey = location?.pathname?.split('/')[3]);
      // do nothing
    }
    if (loader !== locationKey) setloader(locationKey);
  }

  const formatFilename = (filename) => {
    let formattedName = filename.replace('.csv', '');

    if (formattedName.length > 33) {
      formattedName = `${formattedName.substring(0, 30)}...`;
    }

    return formattedName;
  };

  useEffect(() => {
    let host = window.location.host;
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
    if (host === 'app.neverspam.io') {
      let intercomBootObj = {
        app_id: 'c5w3j811',
        user_id: localStorage.getItem('userId'),
        name:
          authResult.attributes['custom:first_name'] +
          ' ' +
          authResult.attributes['custom:last_name'],
        email: authResult.attributes['email'],
        // created_at: "1312182000" // Signup date as a Unix timestamp
      };
      window.Intercom('boot', intercomBootObj);
    }
    let createdAt = planData?.planData?.records[0]?.createdAt?.split('.');

    let surveyJson = {
      email: authResult.attributes['email'],
      name:
        authResult.attributes['custom:first_name'] +
        ' ' +
        authResult.attributes['custom:last_name'],

      createdAt: createdAt && createdAt[0] ? createdAt[0] + 'Z' : '', //
      properties: {
        userId: localStorage.getItem('userId'),
        companyId: localStorage.getItem('companyId'),
      },
    };

    window.delighted.survey(surveyJson);

    if (!mailboxDomainLists && !mailboxDomainLists?.length) {
      dispatch(mailboxDomainListsAction(companyId));
    }

    if (!outreachSafetyNetMailboxes || !outreachSafetyNetMailboxes.length) {
      dispatch(getOutreachMailboxes());
    }

    if (!closeSafetyNetMailboxes || !closeSafetyNetMailboxes.length) {
      dispatch(getCloseMailboxes());
    }

    if (!salesloftSafetyNetMailboxes || !salesloftSafetyNetMailboxes.length) {
      dispatch(getSalesloftMailboxes());
    }

    if (!throttlingMailboxes || !throttlingMailboxes.length) {
      dispatch(getThrottlingMailboxes());
    }

    if (!safetyNetEverywhereJobs || !safetyNetEverywhereJobs.length) {
      dispatch(getSafetyNetEverywhereJobsAction());
    }

    if (!safetyNetEverywhereCreditsData) {
      dispatch(getRemainingSafetyNetCreditsAction());
    }

    location?.pathname?.includes(USER_SENDING_PATTERN) && sendingPattern('open');

    location?.pathname?.includes(USER_SAFETY_NET) && safetyNet('open');

    location?.pathname?.includes(USER_THROTTLING) && throttling('open');

    location?.pathname?.includes(USER_SAFETY_NET_EVERYWHERE) && safetyNetEverywhere('open');
  }, []);

  const { planData, error: planError } = useSelector((state) => state.planDetails);
  const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
  const noPlanFound = !planData || !planData.planData;
  if (!authResult.attributes['nickname'] && noPlanFound) {
    localStorage.setItem(
      'signupUserData',
      JSON.stringify({
        userId: localStorage.getItem('userId'),
        companyId: localStorage.getItem('companyId'),
      }),
    );
    localStorage.setItem('signupUserEmail', authResult.attributes['email']);
    planData?.errorMessage || planError?.errorMessage
      ? history.push('/pricing')
      : history.push('/plan-not-found');
  }
  const planPermission = JSON.parse(planData?.planData?.records[0]?.plan_policy ?? '{}');
  const contentTestsFlag = planPermission?.contentTests;

  if (planData?.planData?.records[0]?.subscription_status === 'cancelled') {
    history.push('/pricing');
  }

  useEffect(() => {
    if (loader === 5) {
      (!demoData || !contentTestsFlag) && history.push(USER_MAILBOXES);
    }
  }, [loader]);
  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  const { Sider } = Layout;
  return (
    <Sider theme="white" className="h-screen fixed">
      <div className="logo ns-menu" style={{ margin: '20px 25px 11px 14px' }}>
        <Image width={135} height={40} preview={false} src="/images/logo_allegrow.svg"></Image>
      </div>
      <div className="static mt-20">
        {showSendingPattern && (
          <div className="">
            <Menu
              className="v-align-2 ns-menu-5"
              mode={`${'inline'}`}
              selectedKeys={loader}
              expandIcon={'  '}
              subMenuOpenDelay={20}
              subMenuCloseDelay={10}
              openKeys={openKeys}
              theme="white">
              <SubMenu
                className="ns-menu-5"
                onTitleClick={() => sendingPattern('close')}
                icon={<LeftOutlined />}
                title="Sending Pattern"
                key="subSendingPattern">
                {!!(mailboxDomainLists && mailboxDomainLists?.length) ? (
                  mailboxDomainLists?.map((domain) => {
                    return (
                      <Menu.ItemGroup
                        className="ns-sub-menu"
                        key={domain.domain_name}
                        title={
                          <Tooltip mouseEnterDelay={1} placement="right" title={domain.domain_name}>
                            <Text className="text-gray-500 w-full" ellipsis={true}>
                              {domain.domain_name}
                            </Text>
                          </Tooltip>
                        }>
                        {domain.sub_accounts.map((emailAccount, emailIndex) => (
                          <Menu.Item className="domain-email-ellipsis" key={emailAccount.id}>
                            <Tooltip
                              mouseEnterDelay={1}
                              className=""
                              placement="left"
                              title={emailAccount.email}>
                              <Link className="" to={USER_SENDING_PATTERN + emailAccount.id}>
                                <Text className="w-full text-gray-500" ellipsis={true}>
                                  <span className="text-black">
                                    {emailAccount.email?.split('@')[0]}
                                  </span>
                                  @{emailAccount.email?.split('@')[1]}
                                </Text>
                              </Link>
                            </Tooltip>
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                    );
                  })
                ) : (
                  <Menu.ItemGroup
                    className="ns-sub-menu"
                    key={444}
                    title={'No connections are available'}></Menu.ItemGroup>
                )}
              </SubMenu>
            </Menu>
          </div>
        )}
        {showSafetyNet && (
          <div className="">
            <Menu
              className="v-align-2 ns-menu-5"
              mode={`${'inline'}`}
              selectedKeys={loader}
              expandIcon={'  '}
              subMenuOpenDelay={20}
              subMenuCloseDelay={10}
              openKeys={openKeys}
              theme="white">
              <SubMenu
                className="ns-menu-5"
                onTitleClick={() => safetyNet('close')}
                icon={<LeftOutlined />}
                title="Safety Net"
                key="subSafetyNet">
                {!!(mailboxDomainLists && mailboxDomainLists?.length) ? (
                  <>
                    <Menu.Item className="domain-email-ellipsis" key={-1}>
                      <Tooltip
                        mouseEnterDelay={1}
                        className=""
                        placement="left"
                        title="All Mailboxes">
                        <Link className="" to={USER_SAFETY_NET}>
                          <Text className="w-full text-gray-500" ellipsis={true}>
                            <span className="text-black">{'All Mailboxes'}</span>
                          </Text>
                        </Link>
                      </Tooltip>
                    </Menu.Item>

                    {(outreachSafetyNetMailboxes || [])
                      .concat(closeSafetyNetMailboxes || [])
                      .concat(salesloftSafetyNetMailboxes || [])
                      .reduce((acc, mailbox) => {
                        if (!acc.some((item) => item.email === mailbox.email)) {
                          acc.push(mailbox);
                        }
                        return acc;
                      }, [])
                      .map((mailbox, emailIndex) => (
                        <Menu.Item className="domain-email-ellipsis" key={mailbox.id}>
                          <Tooltip
                            mouseEnterDelay={1}
                            className=""
                            placement="left"
                            title={mailbox.email}>
                            <Link className="" to={USER_SAFETY_NET + mailbox.id}>
                              <Text className="w-full text-gray-500" ellipsis={true}>
                                <span className="text-black">{mailbox.email?.split('@')[0]}</span>@
                                {mailbox.email?.split('@')[1]}
                              </Text>
                            </Link>
                          </Tooltip>
                        </Menu.Item>
                      ))}
                  </>
                ) : (
                  <Menu.ItemGroup
                    className="ns-sub-menu"
                    key={444}
                    title={'No connections are available'}></Menu.ItemGroup>
                )}
              </SubMenu>
            </Menu>
          </div>
        )}

        {showThrottling && (
          <div className="">
            <Menu
              className="v-align-2 ns-menu-5"
              mode={`${'inline'}`}
              selectedKeys={loader}
              expandIcon={'  '}
              subMenuOpenDelay={20}
              subMenuCloseDelay={10}
              openKeys={openKeys}>
              <SubMenu
                className="ns-menu-5"
                onTitleClick={() => throttling('close')}
                icon={<LeftOutlined />}
                title="Throttling"
                key="subThrottling">
                {!!(mailboxDomainLists && mailboxDomainLists?.length) ? (
                  <>
                    <Menu.Item className="domain-email-ellipsis" key={-2}>
                      <Tooltip
                        mouseEnterDelay={1}
                        className=""
                        placement="left"
                        title="All Mailboxes">
                        <Link className="" to={USER_THROTTLING}>
                          <Text className="w-full text-gray-500" ellipsis={true}>
                            <span className="text-black">{'All Mailboxes'}</span>
                          </Text>
                        </Link>
                      </Tooltip>
                    </Menu.Item>

                    {(throttlingMailboxes || []).map((mailbox) => (
                      <Menu.Item className="domain-email-ellipsis" key={mailbox.id}>
                        <Tooltip
                          mouseEnterDelay={1}
                          className=""
                          placement="left"
                          title={mailbox.email}>
                          <Link className="" to={USER_THROTTLING + mailbox.mailboxId}>
                            <Text className="w-full text-gray-500" ellipsis={true}>
                              <span className="text-black">{mailbox.email.split('@')[0]}</span>@
                              {mailbox.email.split('@')[1]}
                            </Text>
                          </Link>
                        </Tooltip>
                      </Menu.Item>
                    ))}
                  </>
                ) : (
                  <Menu.ItemGroup
                    className="ns-sub-menu"
                    key={444}
                    title={'No connections are available'}></Menu.ItemGroup>
                )}
              </SubMenu>
            </Menu>
          </div>
        )}

        {showSafetyNetEverywhere && (
          <div className="">
            <Menu
              className="v-align-2 ns-menu-5"
              mode={`${'inline'}`}
              selectedKeys={loader}
              expandIcon={'  '}
              subMenuOpenDelay={20}
              subMenuCloseDelay={10}
              openKeys={openKeys}>
              <SubMenu
                className="ns-menu-5"
                onTitleClick={() => safetyNetEverywhere('close')}
                icon={<LeftOutlined />}
                title="SN Everywhere"
                key="subSafetyNetEverywhere">
                {true ? (
                  <>
                    <Menu.Item className="domain-email-ellipsis" key={-4}>
                      <Tooltip
                        mouseEnterDelay={1}
                        className=""
                        placement="left"
                        title="Most Recent">
                        <Link className="" to={USER_SAFETY_NET_EVERYWHERE + 'most-recent'}>
                          <Text className="w-full text-gray-500" ellipsis={true}>
                            <span className="text-black">{'Most Recent'}</span>
                          </Text>
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                    <Menu.Item className="domain-email-ellipsis" key={-5}>
                      <Tooltip
                        mouseEnterDelay={1}
                        className=""
                        placement="left"
                        title="Not Yet Exported">
                        <Link className="" to={USER_SAFETY_NET_EVERYWHERE + 'not-yet-exported'}>
                          <Text className="w-full text-gray-500" ellipsis={true}>
                            <span className="text-black">{'Not Yet Exported'}</span>
                          </Text>
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                    <Menu.Item className="domain-email-ellipsis" key={-3}>
                      <Tooltip mouseEnterDelay={1} className="" placement="left" title="All Data">
                        <Link className="" to={USER_SAFETY_NET_EVERYWHERE + 'all-data'}>
                          <Text className="w-full text-gray-500" ellipsis={true}>
                            <span className="text-black">{'All Data'}</span>
                          </Text>
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                    <SafetyNetEverywhereCreditsUsage
                      safetyNetEverywhereCreditsData={safetyNetEverywhereCreditsData}
                    />
                  </>
                ) : (
                  <Menu.ItemGroup key="no-data" title="No data available"></Menu.ItemGroup>
                )}
              </SubMenu>
            </Menu>
          </div>
        )}

        {!showSafetyNet && !showSendingPattern && !showThrottling && !showSafetyNetEverywhere && (
          <div className="">
            <Menu
              subMenuOpenDelay={2}
              subMenuCloseDelay={1}
              theme="white"
              className="ns-menu-10 h-screen"
              mode={`${'vertical'}`}
              selectedKeys={loader}>
              <Menu.Item key="1" icon={<MailOutlined />}>
                <Link to={USER_MAILBOXES}>Performance</Link>
              </Menu.Item>
              <Menu.Item key="9" icon={<GoldOutlined />}>
                <Link to={USER_LEADERBOARD}>Leaderboard</Link>
              </Menu.Item>
              <Menu.Item key="7" icon={<BarChartOutlined />}>
                <Link to="#" onClick={() => sendingPattern('open')}>
                  Sending Pattern
                </Link>
              </Menu.Item>

              <Menu.Item key="10" icon={<SafetyOutlined />}>
                <Link to={USER_SAFETY_NET} onClick={() => safetyNet('open')}>
                  Safety Net
                </Link>
              </Menu.Item>

              <Menu.Item key="11" icon={<ClockCircleOutlined />}>
                <Link to={USER_THROTTLING} onClick={() => throttling('open')}>
                  Throttling
                </Link>
              </Menu.Item>

              {canViewContentTests && (
                <Menu.Item key="5" icon={<LabIcon />}>
                  <Link to={USER_CONTENT_TESTS}>Content Tests</Link>
                </Menu.Item>
              )}

              <Menu.Item key="6" icon={<GlobalOutlined />}>
                <Link
                  to={USER_SAFETY_NET_EVERYWHERE + 'most-recent'}
                  onClick={() => safetyNetEverywhere('open')}>
                  SN Everywhere
                </Link>
              </Menu.Item>

              <Menu.Item key="2" icon={<BulbOutlined />}>
                <Link to={USER_INSIGHTS}>Insights</Link>
              </Menu.Item>
              <Menu.Item
                key="3"
                className="ns-absolute bottom-0 w-full"
                style={{ marginBottom: '45px' }}
                icon={<SettingOutlined />}>
                <Link to={USER_SETTINGS}>Settings</Link>
              </Menu.Item>
              <Menu.Item key="4" className="ns-absolute bottom-0 w-full" icon={<LogoutOutlined />}>
                <Link to="#" onClick={handleLogout}>
                  Logout
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        )}
      </div>
    </Sider>
  );
};

export default SiderLayout;
