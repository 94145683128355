import {
  CLOSE_API_REQUEST,
  CLOSE_API_REQUEST_FAIL,
  CLOSE_API_REQUEST_SUCCESS,
  CONNECT_OUTREACH_API_REQUEST,
  CONNECT_OUTREACH_API_REQUEST_FAIL,
  CONNECT_OUTREACH_API_REQUEST_SUCCESS,
  CONNECT_SALESLOFT_API_REQUEST,
  CONNECT_SALESLOFT_API_REQUEST_FAIL,
  CONNECT_SALESLOFT_API_REQUEST_SUCCESS,
  GET_CADENCES_FOR_USER_REQUEST,
  GET_CADENCES_FOR_USER_REQUEST_FAIL,
  GET_CADENCES_FOR_USER_REQUEST_SUCCESS,
  GET_CLOSE_INFO_API_REQUEST,
  GET_CLOSE_INFO_API_REQUEST_FAIL,
  GET_CLOSE_INFO_API_REQUEST_SUCCESS,
  GET_CLOSE_MAILBOXES_API_REQUEST,
  GET_CLOSE_MAILBOXES_API_REQUEST_FAIL,
  GET_CLOSE_MAILBOXES_API_REQUEST_SUCCESS,
  GET_CUSTOM_FIELDS_INFO_REQUEST,
  GET_CUSTOM_FIELDS_INFO_REQUEST_FAIL,
  GET_CUSTOM_FIELDS_INFO_REQUEST_SUCCESS,
  GET_INTEGRATION_DETAILS_API_REQUEST,
  GET_INTEGRATION_DETAILS_API_REQUEST_FAIL,
  GET_INTEGRATION_DETAILS_API_REQUEST_SUCCESS,
  GET_OUTREACH_INFO_API_REQUEST,
  GET_OUTREACH_INFO_API_REQUEST_FAIL,
  GET_OUTREACH_INFO_API_REQUEST_SUCCESS,
  GET_OUTREACH_MAILBOXES_API_REQUEST,
  GET_OUTREACH_MAILBOXES_API_REQUEST_FAIL,
  GET_OUTREACH_MAILBOXES_API_REQUEST_SUCCESS,
  GET_SALESLOFT_INFO_API_REQUEST,
  GET_SALESLOFT_INFO_API_REQUEST_FAIL,
  GET_SALESLOFT_INFO_API_REQUEST_SUCCESS,
  GET_SALESLOFT_MAILBOXES_API_REQUEST,
  GET_SALESLOFT_MAILBOXES_API_REQUEST_FAIL,
  GET_SALESLOFT_MAILBOXES_API_REQUEST_SUCCESS,
  UPDATE_CADENCE_STATUS_API_REQUEST,
  UPDATE_CADENCE_STATUS_API_REQUEST_FAIL,
  UPDATE_CADENCE_STATUS_API_REQUEST_SUCCESS,
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CUSTOM_FIELDS_REQUEST_FAIL,
  UPDATE_CUSTOM_FIELDS_REQUEST_SUCCESS,
  UPDATE_MAILBOX_STATUS_API_REQUEST,
  UPDATE_MAILBOX_STATUS_API_REQUEST_FAIL,
  UPDATE_MAILBOX_STATUS_API_REQUEST_SUCCESS,
  USER_MAILBOX_RECOMMEND,
  USER_MAILBOX_RECOMMEND_FAIL,
  USER_MAILBOX_RECOMMEND_SUCCESS,
} from '../Constants/integrationConstants';

export function closeApiReducer(state = { closeApiData: null }, action) {
  switch (action.type) {
    case CLOSE_API_REQUEST:
      return { loading: true, closeApiData: null };
    case CLOSE_API_REQUEST_SUCCESS:
      return { loading: false, closeApiData: action.payload };
    case CLOSE_API_REQUEST_FAIL:
      return { loading: false, closeApiError: action.payload };
    default:
      return state;
  }
}

export function getOutreachInfoApiReducer(state = { outreachApiData: null }, action) {
  switch (action.type) {
    case GET_OUTREACH_INFO_API_REQUEST:
      return { loading: true, outreachApiData: null };
    case GET_OUTREACH_INFO_API_REQUEST_SUCCESS:
      return { loading: false, outreachApiData: action.payload };
    case GET_OUTREACH_INFO_API_REQUEST_FAIL:
      return { loading: false, outreachApiError: action.payload };
    default:
      return state;
  }
}

export function getSalesloftInfoApiReducer(state = { salesloftApiData: null }, action) {
  switch (action.type) {
    case GET_SALESLOFT_INFO_API_REQUEST:
      return { loading: true, salesloftApiData: null };
    case GET_SALESLOFT_INFO_API_REQUEST_SUCCESS:
      return { loading: false, salesloftApiData: action.payload };
    case GET_SALESLOFT_INFO_API_REQUEST_FAIL:
      return { loading: false, salesloftApiError: action.payload };
    default:
      return state;
  }
}

export function getCloseInfoApiReducer(state = { closeApiData: null }, action) {
  switch (action.type) {
    case GET_CLOSE_INFO_API_REQUEST:
      return { loading: true, closeApiData: null };
    case GET_CLOSE_INFO_API_REQUEST_SUCCESS:
      return { loading: false, closeApiData: action.payload };
    case GET_CLOSE_INFO_API_REQUEST_FAIL:
      return { loading: false, closeApiError: action.payload };
    default:
      return state;
  }
}

export function outreachApiReducer(state = { outreachApiData: null }, action) {
  switch (action.type) {
    case CONNECT_OUTREACH_API_REQUEST:
      return { loading: true, outreachApiData: null };
    case CONNECT_OUTREACH_API_REQUEST_SUCCESS:
      return { loading: false, outreachApiData: action.payload };
    case CONNECT_OUTREACH_API_REQUEST_FAIL:
      return { loading: false, outreachApiError: action.payload };
    default:
      return state;
  }
}

export function salesloftApiReducer(state = { salesloftApiData: null }, action) {
  switch (action.type) {
    case CONNECT_SALESLOFT_API_REQUEST:
      return { loading: true, salesloftApiData: null };
    case CONNECT_SALESLOFT_API_REQUEST_SUCCESS:
      return { loading: false, salesloftApiData: action.payload };
    case CONNECT_SALESLOFT_API_REQUEST_FAIL:
      return { loading: false, salesloftApiError: action.payload };
    default:
      return state;
  }
}

export function getOutreachMailboxesApiReducer(state = { outreachMailboxesApiData: null }, action) {
  switch (action.type) {
    case GET_OUTREACH_MAILBOXES_API_REQUEST:
      return { loading: true, outreachMailboxesApiData: null };
    case GET_OUTREACH_MAILBOXES_API_REQUEST_SUCCESS:
      return { loading: false, outreachMailboxesApiData: action.payload };
    case GET_OUTREACH_MAILBOXES_API_REQUEST_FAIL:
      return { loading: false, outreachApiError: action.payload };
    default:
      return state;
  }
}

export function getCloseMailboxesApiReducer(state = { closeMailboxesApiData: null }, action) {
  switch (action.type) {
    case GET_CLOSE_MAILBOXES_API_REQUEST:
      return { loading: true, closeMailboxesApiData: null };
    case GET_CLOSE_MAILBOXES_API_REQUEST_SUCCESS:
      return { loading: false, closeMailboxesApiData: action.payload };
    case GET_CLOSE_MAILBOXES_API_REQUEST_FAIL:
      return { loading: false, closeApiError: action.payload };
    default:
      return state;
  }
}

export function getSalesloftMailboxesApiReducer(
  state = { salesloftMailboxesApiData: null },
  action,
) {
  switch (action.type) {
    case GET_SALESLOFT_MAILBOXES_API_REQUEST:
      return { loading: true, salesloftMailboxesApiData: null };
    case GET_SALESLOFT_MAILBOXES_API_REQUEST_SUCCESS:
      return { loading: false, salesloftMailboxesApiData: action.payload };
    case GET_SALESLOFT_MAILBOXES_API_REQUEST_FAIL:
      return { loading: false, salesloftApiError: action.payload };
    default:
      return state;
  }
}

export function updateMailboxStatusApiReducer(state = { mailboxData: null }, action) {
  switch (action.type) {
    case UPDATE_MAILBOX_STATUS_API_REQUEST:
      return { loading: true, mailboxData: null };
    case UPDATE_MAILBOX_STATUS_API_REQUEST_SUCCESS:
      return { loading: false, mailboxData: action.payload };
    case UPDATE_MAILBOX_STATUS_API_REQUEST_FAIL:
      return { loading: false, apiError: action.payload };
    default:
      return state;
  }
}

export function updateCadenceStatusApiReducer(state = { cadenceData: null }, action) {
  switch (action.type) {
    case UPDATE_CADENCE_STATUS_API_REQUEST:
      return { loading: true, cadenceData: null };
    case UPDATE_CADENCE_STATUS_API_REQUEST_SUCCESS:
      return { loading: false, cadenceData: action.payload };
    case UPDATE_CADENCE_STATUS_API_REQUEST_FAIL:
      return { loading: false, apiError: action.payload };
    default:
      return state;
  }
}

export function userMailboxRecommendReducer(state = { userMailboxRecommendData: null }, action) {
  switch (action.type) {
    case USER_MAILBOX_RECOMMEND:
      return { loading: true, userMailboxRecommendData: null };
    case USER_MAILBOX_RECOMMEND_SUCCESS:
      return { loading: false, userMailboxRecommendData: action.payload };
    case USER_MAILBOX_RECOMMEND_FAIL:
      return { loading: false, userMailboxRecommendError: action.payload };
    default:
      return state;
  }
}

export function getCadencesForUserReducer(state = { cadencesForUserData: null }, action) {
  switch (action.type) {
    case GET_CADENCES_FOR_USER_REQUEST:
      return { loading: true, cadencesForUserData: null };
    case GET_CADENCES_FOR_USER_REQUEST_SUCCESS:
      return { loading: false, cadencesForUserData: action.payload };
    case GET_CADENCES_FOR_USER_REQUEST_FAIL:
      return { loading: false, cadencesForUserError: action.payload };
    default:
      return state;
  }
}

export function getIntegrationDetailsApiReducer(state = { integrationData: null }, action) {
  switch (action.type) {
    case GET_INTEGRATION_DETAILS_API_REQUEST:
      return { loading: true, integrationData: null, integrationError: null };
    case GET_INTEGRATION_DETAILS_API_REQUEST_SUCCESS:
      return { loading: false, integrationData: action.payload };
    case GET_INTEGRATION_DETAILS_API_REQUEST_FAIL:
      return { loading: false, integrationError: action.payload };
    default:
      return state;
  }
}

export function getCustomFieldsInfoReducer(state = { customFieldsData: null }, action) {
  switch (action.type) {
    case GET_CUSTOM_FIELDS_INFO_REQUEST:
    case UPDATE_CUSTOM_FIELDS_REQUEST:
      return { loading: true, customFieldsData: action.payload };
    case GET_CUSTOM_FIELDS_INFO_REQUEST_SUCCESS:
    case UPDATE_CUSTOM_FIELDS_REQUEST_SUCCESS:
      return { loading: false, customFieldsData: action.payload };
    case GET_CUSTOM_FIELDS_INFO_REQUEST_FAIL:
    case UPDATE_CUSTOM_FIELDS_REQUEST_FAIL:
      return { loading: false, customFieldsError: action.payload };
    default:
      return state;
  }
}
