import '../assets/css/stripe-example2.css';

import { Alert, Spin, notification } from 'antd';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useMemo, useState } from 'react';

import AxiosApi from '../config/axios-api';
import ConfirmSignupMessageModal from '../Modals/ConfirmSignupMessageModal';
import { USER_SETTINGS } from '../Routes/Constants/RouteUrls';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useResponsiveFontSize from '../responsive/useResponsiveFontSize';

// import '../assets/css/stripe.css'

const returnUrl = `${USER_SETTINGS}?tab=4`;
const openNotification = (placements) => {
  notification[placements.type]({
    message: `${placements.msg}`,
    description: placements.description,
    placement: placements.apperence,
  });
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      // style: {
      //   base: {
      //     fontSize,
      //     color: "#424770",
      //     letterSpacing: "0.025em",
      //     fontFamily: "Source Code Pro, monospace",
      //     "::placeholder": {
      //       color: "#aab7c4"
      //     }
      //   },
      //   invalid: {
      //     color: "#9e2146"
      //   }
      // }
      style: {
        base: {
          color: '#32325D',
          fontWeight: 500,
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',

          '::placeholder': {
            color: '#FFCCA5',
          },
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const CheckoutForm = ({ setStripeConfig, stripeConfig, stripePromise, setStripeElementsModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loader, setloader] = useState(false);
  const [spinTip, setSpinTip] = useState('Payment Processing...');
  const [paymentError, setPaymentError] = useState('');
  const [visible, setVisible] = useState(false);
  const { priceId, planId, planPrice, planName } = stripeConfig;

  const confirmSubscription = async (session) => {
    const confirmSubscription = await AxiosApi.post(
      'billing/retrive-checkout-session',
      JSON.stringify(session.data.result),
    );
    if (confirmSubscription?.data?.successMessage === 'Success') {
      successMsg('confirmSuccess');
    }
  };

  const closeStripeElementsModal = () => {
    if (planName.includes('Lifetime')) {
      setStripeElementsModal(false);
      return;
    }
    history.push(returnUrl);
  };

  const successMsg = async (session) => {
    let companyId = localStorage.getItem('companyId');
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
    // successfully payment done
    if (
      (session?.data?.result?.subscriptionStatus === 'active' &&
        session?.data?.result?.paymentIntentStatus === 'succeeded') ||
      session === 'confirmSuccess'
    ) {
      openNotification({
        type: 'success',
        msg: 'Success',
        description: 'Payment successful!',
        apperence: 'topRight',
      });

      if (process.env.REACT_APP_FIRST_PROMOTER_CID)
        await window.fpr('referral', {
          email: authResult?.attributes['email'] ?? localStorage.getItem('signupUserEmail'),
        });

      if (companyId) {
        setStripeConfig({});
        if (session === 'confirmSuccess') {
          await dispatch(planDetailsAction(companyId, 'update'));
          closeStripeElementsModal();
        } else {
          setSpinTip('Assigning plan to your account...');
          setTimeout(async () => {
            await dispatch(planDetailsAction(companyId, 'update'));
            closeStripeElementsModal();
          }, 6000);
        }
      } else {
        !authResult?.attributes['email_verified'] && setVisible(true);
      }
    }
  };

  const handlePayment = async (paymentMethod) => {
    // Get Stripe.js instance
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
    //const stripe = await stripePromise;
    const billingData = {
      priceId,
      paymentMethod,
      signupUserData: { ...JSON.parse(localStorage.getItem('signupUserData')), ...{ planId } },
      signupUserEmail: authResult?.attributes['email'] ?? localStorage.getItem('signupUserEmail'),
    };
    const userId = localStorage.getItem('userId');
    if (userId) {
      const companyId = localStorage.getItem('companyId');
      billingData.signupUserData.userId = Number(userId);
      billingData.signupUserData.companyId = Number(companyId);
      if (planName.includes('Lifetime')) billingData.signupUserData.planType = planName;
    }
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    const session = await AxiosApi.post(
      'billing/create-elements-session',
      JSON.stringify({ billingData }),
    ).catch((error) => {
      setloader(false);
      openNotification({
        type: 'error',
        msg: 'Error',
        description:
          (typeof error.errorMessage === 'string' && error.errorMessage) ||
          error.message ||
          'Something went wrong',
        apperence: 'topRight',
      });
    });
    if (session?.data?.result?.paymentIntentStatus === 'requires_payment_method') {
      setloader(false);
      openNotification({
        type: 'error',
        msg: 'Error',
        description: 'Your payment card has failed. Please update your payment card.',
        apperence: 'topRight',
      });
      return;
    }
    if (
      session?.data?.result?.paymentIntentStatus === 'requires_action' &&
      session?.data?.result?.clientSecret
    ) {
      stripe
        .confirmCardPayment(session.data.result.clientSecret, { setup_future_usage: 'off_session' })
        .then(function (result) {
          if (result.error) {
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setloader(false);
            openNotification({
              type: 'error',
              msg: 'Error',
              description: result.error.message,
              apperence: 'topRight',
            });
          } else {
            // Show a success message to your customer
            //confirmSubscription(subscription.id);
            confirmSubscription(session);
          }
        })
        .catch(function (error) {});
    } else {
      successMsg(session);
    }

    // When the customer clicks on the button, redirect them to Checkout.
    // const result = await stripe.redirectToCheckout({
    //   sessionId: session.data.result.sessionId,
    // });

    // if (result.error) {
    //   setloader(false)
    //   // If `redirectToCheckout` fails due to a browser or network
    //   // error, display the localized error message to your customer
    //   // using `result.error.message`.
    // }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setloader(true);
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        email: authResult?.attributes['email'] ?? localStorage.getItem('signupUserEmail'),
        address: {
          city: document.getElementById('example2-city').value ?? null,
          country: null,
          line1: document.getElementById('example2-address').value ?? null,
          line2: null,
          postal_code: document.getElementById('example2-zip').value ?? null,
          state: document.getElementById('example2-state').value ?? null,
        },
      },
    });

    if (payload.error) {
      setPaymentError(payload.error.message);
      setloader(false);
      return;
    }
    if (payload?.paymentMethod?.id) {
      await handlePayment(payload.paymentMethod.id);
    }
  };

  return (
    <Spin tip={spinTip} spinning={loader}>
      <div
        className={`flex flex-col items-center ${
          planName.includes('Lifetime') ? 'pb-8' : 'pb-16'
        } `}>
        {paymentError && (
          <Alert message={paymentError} className="ns-rounded-lg" type="error" showIcon />
        )}
        <ConfirmSignupMessageModal visible={visible} onCancel={() => setVisible(false)} />
        <form
          className={`text-left ${!!loader && 'hidden '} cell example example2 ${
            planName.includes('Lifetime') ? 'w-full' : 'w-1/2'
          }`}
          onSubmit={handleSubmit}>
          <div data-locale-reversible>
            <div className="row">
              <div className="field">
                <input
                  id="example2-address"
                  data-tid="elements_examples.form.address_placeholder"
                  className="input empty"
                  type="text"
                  placeholder="185 Berry St"
                  required=""
                  autoComplete="off"
                />
                <label htmlFor="example2-address" data-tid="elements_examples.form.address_label">
                  Address
                </label>
                <div className="baseline"></div>
              </div>
            </div>
            <div className="row" data-locale-reversible>
              <div className="field half-width">
                <input
                  id="example2-city"
                  data-tid="elements_examples.form.city_placeholder"
                  className="input empty"
                  type="text"
                  placeholder="San Francisco"
                  required=""
                  autoComplete="off"
                />
                <label htmlFor="example2-city" data-tid="elements_examples.form.city_label">
                  City
                </label>
                <div className="baseline"></div>
              </div>
              <div className="field quarter-width">
                <input
                  id="example2-state"
                  data-tid="elements_examples.form.state_placeholder"
                  className="input empty"
                  type="text"
                  placeholder="CA"
                  required=""
                  autoComplete="off"
                />
                <label htmlFor="example2-state" data-tid="elements_examples.form.state_label">
                  State
                </label>
                <div className="baseline"></div>
              </div>
              <div className="field quarter-width">
                <input
                  id="example2-zip"
                  data-tid="elements_examples.form.postal_code_placeholder"
                  className="input empty"
                  type="text"
                  placeholder="94107"
                  required=""
                  autoComplete="off"
                />
                <label htmlFor="example2-zip" data-tid="elements_examples.form.postal_code_label">
                  ZIP
                </label>
                <div className="baseline"></div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="field">
                <CardNumberElement options={options} className="input" />
                <label>Card number</label>
                <div className="baseline"></div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="field half-width">
                <CardExpiryElement options={options} className="input" />
                <label>Expiration date</label>
                <div className="baseline"></div>
              </div>
              <div className="field half-width">
                <CardCvcElement options={options} className="input" />
                <label>CVC</label>
                <div className="baseline"></div>
              </div>
            </div>
            <div className="row">
              <div className="field">
                <button className="ns-rounded-lg" type="submit" disabled={!stripe}>
                  Pay ${planName.includes('Scale Plus') ? planPrice * 25 : planPrice} for{' '}
                  {planName.includes('Lifetime') ? ' 1 mailbox purchase' : planName}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Spin>
  );
};

const StripeSplitElementsScreen = ({
  setStripeConfig,
  stripeConfig,
  stripePromise,
  setStripeElementsModal,
}) => {
  useEffect(() => {
    // Floating labels
    var inputs = document.querySelectorAll('.cell.example.example2 .input');
    Array.prototype.forEach.call(inputs, function (input) {
      input.addEventListener('focus', function () {
        input.classList.add('focused');
      });
      input.addEventListener('blur', function () {
        input.classList.remove('focused');
      });
      input.addEventListener('keyup', function () {
        if (input.value.length === 0) {
          input.classList.add('empty');
        } else {
          input.classList.remove('empty');
        }
      });
    });
    return () => {};
  }, []);
  return (
    <div className="DemoWrapper">
      <div className="DemoPickerWrapper">
        <div className="Demo">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              setStripeElementsModal={setStripeElementsModal}
              setStripeConfig={setStripeConfig}
              stripeConfig={stripeConfig}
            />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default StripeSplitElementsScreen;
