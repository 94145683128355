// import axiosRetry from 'axios-retry';
import {
  PLAN_ALL_DETAILS_REQUEST,
  PLAN_ALL_DETAILS_REQUEST_FAIL,
  PLAN_ALL_DETAILS_REQUEST_SUCCESS,
  PLAN_DETAILS_REQUEST,
  PLAN_DETAILS_REQUEST_FAIL,
  PLAN_DETAILS_REQUEST_SUCCESS,
} from '../Constants/planDetailsConstant';

import AxiosApi from '../../config/axios-api';
import { useHistory } from 'react-router';

// axiosRetry(AxiosApi, { retries: 3 });

const planDetailsAction = (companyId, type) => async (dispatch) => {
  try {
    let dataList;
    if (type !== 'update') dispatch({ type: PLAN_DETAILS_REQUEST, payload: companyId });
    dataList = await AxiosApi.get('billing/get-company-plan-details/' + companyId).catch(
      async (error) => {
        if (error?.errorMessage) {
          dispatch({ type: PLAN_DETAILS_REQUEST_FAIL, payload: error });
          return false;
        }
        dataList = await AxiosApi.get('billing/get-plan-details/' + companyId).catch(
          async (error) => {
            if (error?.errorMessage) {
              dispatch({ type: PLAN_DETAILS_REQUEST_FAIL, payload: error });
              return false;
            }
            dataList = await AxiosApi.get('billing/get-plan-details/' + companyId).catch(
              (error) => {
                dispatch({ type: PLAN_DETAILS_REQUEST_FAIL, payload: error });
              },
            );
          },
        );
      },
    );
    if (!dataList) return false;
    let { data } = dataList ?? {};
    dispatch({ type: PLAN_DETAILS_REQUEST_SUCCESS, payload: data?.result });
  } catch (error) {
    dispatch({ type: PLAN_DETAILS_REQUEST_FAIL, payload: error });
  }
};

const planAllDetailsAction = () => async (dispatch) => {
  try {
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST });
    let hasPlan = 'false';
    let userId = localStorage.getItem('userId');
    const serializedState = localStorage.getItem('state');
    let visible = '&visible=true';
    if (serializedState !== null && userId) {
      let planData = JSON.parse(serializedState);
      if (planData?.planDetails?.planData?.planData?.records[0]?.name) hasPlan = 'true';
      visible =
        '&visible=' +
        planData?.planDetails?.planData?.planData?.records[0]?.is_visible_in_plan_picker;
    }
    const { data } = await AxiosApi.get(
      'billing/get-all-plans-details/?hasPlan=' + hasPlan + visible,
    );
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST_FAIL, payload: error.message });
  }
};

export { planDetailsAction, planAllDetailsAction };
