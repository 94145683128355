const permissions = [
  { id: 10, permissionName: 'viewCompanyDetails' },
  { id: 11, permissionName: 'updateCompanyDetails' },
  { id: 20, permissionName: 'viewBillingDetails' },
  { id: 21, permissionName: 'updateBillingDetails' },
  { id: 30, permissionName: 'connectIntegration' },
  { id: 31, permissionName: 'viewAllIntegrationMailboxes' },
  { id: 32, permissionName: 'updateAllIntegrationMailboxes' },
  { id: 33, permissionName: 'viewCadences' },
  { id: 34, permissionName: 'updateCadences' },
  { id: 35, permissionName: 'enableCustomFields' },
  { id: 36, permissionName: 'enableSnAutomation' },
  { id: 40, permissionName: 'viewTeam' },
  { id: 41, permissionName: 'updateTeam' },
  { id: 42, permissionName: 'inviteMembers' },
  { id: 50, permissionName: 'viewAllMailboxes' },
  { id: 51, permissionName: 'updateAllMailboxes' },
  { id: 53, permissionName: 'addMailboxes' },
  { id: 55, permissionName: 'deleteAllMailboxes' },
  { id: 60, permissionName: 'viewAllLeaderboardMailboxes' },
  { id: 70, permissionName: 'viewAllMailboxPatterns' },
  { id: 71, permissionName: 'updateAllMailboxPatterns' },
  { id: 91, permissionName: 'updateThrottlingPatterns' },
  { id: 110, permissionName: 'viewContentTests' },
  { id: 111, permissionName: 'createContentTests' },
  { id: 120, permissionName: 'viewAndDownloadAllSnEverywhereData' },
  { id: 121, permissionName: 'uploadSnEverywhere' },
  { id: 130, permissionName: 'viewInsights' },
  { id: 131, permissionName: 'updateInsights' },
  { id: 140, permissionName: 'sendEmails' },
  { id: 141, permissionName: 'receiveEmails' },
];

export const hasPermission = (permissionName) => {
  const userPermissions = JSON.parse(localStorage.getItem('permissions'));

  if (!userPermissions) {
    return false;
  }

  const requestedPermission = permissions.find(
    (permission) => permission.permissionName === permissionName,
  );

  if (!requestedPermission) {
    return false;
  }

  return userPermissions.includes(requestedPermission.id);
};
