import { Button, Modal } from 'antd';

import { Link } from 'react-router-dom';
import React from 'react';

const ConfirmSignupMessageModal = ({ visible, onCancel }) => {
  return (
    <Modal
      className="connect-mailbox-modal ns-rounded-lg"
      visible={visible}
      onCancel={onCancel}
      width={440}
      footer={null}
      centered>
      <div className="flex flex-col justify-center items-center text-center">
        <h2 className="pb-2 text-4xl">Verify Your Email</h2>
        <div className="px-4 py-2">
          <p className="text-gray-700 py-4 text-base">
            One more step: Please search your inbox for a new verification link. Once you've clicked
            this, then log in normally.
          </p>
        </div>
        <h3 className="font-black text-gray-700 text-base mb-8">
          See you inside,
          <br />
          The Allegrow Team
        </h3>
        <Button type="primary" className="rounded-md w-5/6" size="large">
          <Link to="/" className="block text-center">
            I’ve done this, take me to Log In
          </Link>
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmSignupMessageModal;
