import {
  CONTENT_TESTS_CREATE_FAIL,
  CONTENT_TESTS_CREATE_REQUEST,
  CONTENT_TESTS_CREATE_REQUEST_UNLOAD,
  CONTENT_TESTS_CREATE_SUCCESS,
  CONTENT_TESTS_DELETE_FAIL,
  CONTENT_TESTS_DELETE_REQUEST,
  CONTENT_TESTS_DELETE_SUCCESS,
  CONTENT_TESTS_DRAFT_FAIL,
  CONTENT_TESTS_DRAFT_REQUEST,
  CONTENT_TESTS_DRAFT_SUCCESS,
  CONTENT_TESTS_FAIL,
  CONTENT_TESTS_FINISH_FAIL,
  CONTENT_TESTS_FINISH_REQUEST,
  CONTENT_TESTS_FINISH_SUCCESS,
  CONTENT_TESTS_FINISH_SUCCESS_CLEAR,
  CONTENT_TESTS_REQUEST,
  CONTENT_TESTS_SUCCESS,
  SHORTEN_EMAIL_FAIL,
  SHORTEN_EMAIL_REQUEST,
  SHORTEN_EMAIL_SUCCESS,
} from '../Constants/contentTestsConstant';

function contentTestsReducer(state = { contentTests: null }, action) {
  switch (action.type) {
    case CONTENT_TESTS_REQUEST:
      return { loading: true, contentTests: null };
    case CONTENT_TESTS_SUCCESS:
      return { loading: false, contentTests: action.payload };
    case CONTENT_TESTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function contentTestsCreateUpdateReducer(state = { contentTestsCreateUpdate: null }, action) {
  switch (action.type) {
    case CONTENT_TESTS_CREATE_REQUEST_UNLOAD:
      return { loading: false, contentTestsCreateUpdate: null };
    case CONTENT_TESTS_CREATE_REQUEST:
      return { loading: true, contentTestsCreateUpdate: null };
    case CONTENT_TESTS_CREATE_SUCCESS:
      return { loading: false, contentTestsCreateUpdate: action.payload };
    case CONTENT_TESTS_CREATE_FAIL:
      return { loading: false, errorContentTestsCreateUpdate: action.payload };
    default:
      return state;
  }
}

function contentTestsFinishReducer(state = { contentTestsFinish: null }, action) {
  switch (action.type) {
    case CONTENT_TESTS_FINISH_REQUEST:
      return { loadingFinish: true, contentTestsFinish: null };
    case CONTENT_TESTS_FINISH_SUCCESS:
      return { loadingFinish: false, contentTestsFinish: action.payload };
    case CONTENT_TESTS_FINISH_SUCCESS_CLEAR:
      return { loadingFinish: false, contentTestsFinish: null };
    case CONTENT_TESTS_FINISH_FAIL:
      return { loadingFinish: false, errorFinish: action.payload };
    default:
      return state;
  }
}

function contentTestsDeleteReducer(state = { contentTestsDelete: null }, action) {
  switch (action.type) {
    case CONTENT_TESTS_DELETE_REQUEST:
      return { loading: true, contentTestsDelete: null };
    case CONTENT_TESTS_DELETE_SUCCESS:
      return { loading: false, contentTestsDelete: action.payload };
    case CONTENT_TESTS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function contentTestsDraftReducer(state = { contentTestsDraft: null }, action) {
  switch (action.type) {
    case CONTENT_TESTS_DRAFT_REQUEST:
      return { loading: true, contentTestsDraft: null };
    case CONTENT_TESTS_DRAFT_SUCCESS:
      return { loading: false, contentTestsDraft: action.payload };
    case CONTENT_TESTS_DRAFT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function shortenEmailReducer(state = { shortenedEmail: null }, action) {
  switch (action.type) {
    case SHORTEN_EMAIL_REQUEST:
      return { loading: true };
    case SHORTEN_EMAIL_SUCCESS:
      return { loading: false, shortenedEmail: action.payload };
    case SHORTEN_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  contentTestsReducer,
  contentTestsCreateUpdateReducer,
  contentTestsDraftReducer,
  contentTestsDeleteReducer,
  contentTestsFinishReducer,
  shortenEmailReducer,
};
