export const USER_MAILBOXES = '/user/mailboxes';
export const USER_EDIT_MAILBOX_ID = '/user/editmailbox/:mailboxId';
export const USER_EDIT_MAILBOX = '/user/editmailbox/';

export const USER_LEADERBOARD = '/user/leaderboard';

export const USER_INSIGHTS = '/user/insights';

export const USER_SENDING_PATTERN = '/user/sending-pattern/';
export const USER_SENDING_PATTERN_ID = `${USER_SENDING_PATTERN}:mailboxId`;

export const USER_SAFETY_NET = '/user/safety-net/';
export const USER_MAILBOX_SAFETY_NET = `${USER_SAFETY_NET}:mailboxId`;

export const USER_SAFETY_NET_EVERYWHERE = '/user/safety-net-everywhere/';
export const USER_SAFETY_NET_EVERYWHERE_FILTER = `${USER_SAFETY_NET_EVERYWHERE}:filterType`;

export const USER_THROTTLING = '/user/throttling/';
export const USER_MAILBOX_THROTTLING = `${USER_THROTTLING}:mailboxId`;

export const USER_CONTENT_TESTS = '/user/content-tests';
export const USER_CREATE_CONTENT_TESTS = '/user/create-content-test/';
export const USER_CREATE_CONTENT_TESTS_ID = `${USER_CREATE_CONTENT_TESTS}:contentId`;
export const USER_EDIT_CONTENT_TESTS = '/user/edit-content-test/';
export const USER_EDIT_CONTENT_TESTS_ID = `${USER_EDIT_CONTENT_TESTS}:contentId`;

export const USER_SETTINGS = '/user/settings';
export const USER_SETTINGS_MY_PROFILE = USER_SETTINGS + '/my-profile';
export const USER_SETTINGS_COMPANY = USER_SETTINGS + '/company';
export const USER_SETTINGS_SECURITY = USER_SETTINGS + '/security';
export const USER_SETTINGS_SUBSCRIPTION = USER_SETTINGS + '/subscription';
export const USER_SETTINGS_INTEGRATIONS = USER_SETTINGS + '/integrations';
export const USER_SETTINGS_TEAM_DETAILS = USER_SETTINGS + '/team-details';

export const USER_TEAM = '/user/team';
export const USER_PROFILE = '/user/myprofile';
export const USER_SECURITY = '/user/security';
export const USER_SUBSCRIPTION = '/user/subscription';
