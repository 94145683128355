export const defaultMemberPermissions = [10, 40, 53, 60, 121, 130, 140, 141];

export const defaultAdminPermissions = [
  10, 11, 30, 31, 32, 33, 34, 35, 36, 40, 41, 42, 50, 51, 53, 55, 60, 70, 71, 91, 110, 111, 120,
  121, 122, 130, 131, 140, 141,
];

export const defaultOwnerPermissions = [
  10, 11, 20, 21, 30, 31, 32, 33, 34, 35, 36, 40, 41, 42, 50, 51, 53, 55, 60, 70, 71, 91, 110, 111,
  120, 121, 122, 130, 131, 140, 141,
];
