import { Button, Checkbox, Form, Input, Spin, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import AxiosApi from '../config/axios-api';
import FogortPasswordModal from '../Modals/FogortPasswordModal';
import ReactDOM from 'react-dom';
import ResetPasswordModal from '../Modals/ResetPasswordModal';
import { SIGNUP_EMAIL } from '../Constants/Email';
import { USER_MAILBOXES } from '../Routes/Constants/RouteUrls';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import { useDispatch } from 'react-redux';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleReset, setVisibleReset] = useState(false);
  const [loader, setloader] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const userId = localStorage.getItem('userId');
  const companyId = localStorage.getItem('companyId');

  if (userId && companyId) {
    history.push(USER_MAILBOXES);
  }

  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 23,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 23,
    },
  };

  const urlParams = new URLSearchParams(window.location.search);
  const demoData = urlParams.get('demo-data');
  let user;

  const onFinish = async (values) => {
    setloader(true);
    let email = values.email;
    try {
      localStorage.clear();
      user = await Auth.signIn(values.email, values.password).catch(async (error) => {
        if (error.name === 'UserNotFoundException') {
          email = values.email.toLowerCase();
          user = await Auth.signIn(email, values.password);
          return user;
        }
        throw error;
      });
      if (user) {
        if (demoData) localStorage.setItem('demoData', 1);
        localStorage.setItem('AuthenticationResult', JSON.stringify(user));
        let getJwtToken = user.signInUserSession.idToken.jwtToken;
        AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${getJwtToken}`;
        try {
          if (!user.attributes['custom:user_id'] || !user.attributes['custom:company_id']) {
            const userDetails = await AxiosApi.get('user-details/' + email);
            if (
              userDetails.data.result.userData.records &&
              userDetails.data.result.userData.records.length
            ) {
              await Auth.updateUserAttributes(user, {
                'custom:user_id': '' + userDetails.data.result.userData.records[0].id,
                'custom:company_id': '' + userDetails.data.result.userData.records[0].company_id,
              });
              localStorage.setItem('userId', userDetails.data.result.userData.records[0].id);
              localStorage.setItem(
                'companyId',
                userDetails.data.result.userData.records[0].company_id,
              );
              localStorage.setItem('userRole', userDetails.data.result.userData.records[0].role);
              localStorage.setItem(
                'permissions',
                userDetails.data.result.userData.records[0].permissions,
              );
            }
          } else {
            localStorage.setItem('userId', user.attributes['custom:user_id']);
            localStorage.setItem('companyId', user.attributes['custom:company_id']);
            localStorage.setItem('userRole', user.attributes['custom:role']);
            localStorage.setItem('permissions', user.attributes['custom:permissions']);
          }
          window.heap.resetIdentity();
          window.heap.identify(localStorage.getItem('userId'));
          window.heap.addUserProperties({
            'First Name': user.attributes['custom:first_name'],
            'Last Name': user.attributes['custom:last_name'],
            'User Role': user.attributes['custom:role'],
            email,
          });
          process.env.REACT_APP_PROFITWELL_API_KEY && window.profitwell('user_email', email);
          await dispatch(planDetailsAction(localStorage.getItem('companyId')));
        } catch (e) {}
        history.push(USER_MAILBOXES);
      }
    } catch (e) {
      setloader(false);
      if (e.message === 'User is not confirmed.') {
        e.message = (
          <>
            User not verified: Please look for a verification email from {SIGNUP_EMAIL}
            <p> and click the link inside it to verify your account.</p>
          </>
        );
      }
      message.warning('Incorrect details', 10);
    }
  };
  const openFogortPasswordModal = () => {
    setVisible(true);
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    return () => {
      ReactDOM.render(
        <>
          <meta name="description" content="Allegrow" />
        </>,
        document.getElementById('metaDescription'),
      );
    };
  }, []);

  return (
    <>
      <div className="w-46 pb-8 mt-24">
        <h4 className="Welcome-Please-sign">Welcome back. Please Log in below.</h4>
      </div>
      <div className="w-46 pb-12">
        <Spin tip="Login..." spinning={loader}>
          <Form
            {...layout}
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              autoFocus
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                  type: 'email',
                },
              ]}
              value={email}
              onChange={(e) => setEmail(e.target.value)}>
              <Input placeholder="Email" className="float-left rounded-md" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              value={password}
              onChange={(e) => setPassword(e.target.value)}>
              <Input.Password
                autoComplete="current-password"
                placeholder="Password"
                className="rounded-md"
              />
            </Form.Item>

            <Form.Item className="mb-3">
              <Form.Item className="w-40" name="remember" valuePropName="checked" noStyle>
                <Checkbox className="float-left">Remember me</Checkbox>
              </Form.Item>
              <Button
                type="link"
                onClick={() => openFogortPasswordModal()}
                className="p-0 pb-3 float-right"
                size="large">
                Forgot password
              </Button>
              <FogortPasswordModal
                setForgotEmail={setForgotEmail}
                visible={visible}
                setVisible={setVisible}
                setVisibleReset={setVisibleReset}
                onCancel={() => setVisible(false)}
              />
              <ResetPasswordModal
                _email={forgotEmail}
                visible={visibleReset}
                onCancel={() => setVisibleReset(false)}
              />
            </Form.Item>

            <Form.Item className="" {...tailLayout}>
              <Button
                type="primary"
                className="float-left w-40 rounded-md ns-bg-green ns-border-green"
                size="large"
                htmlType="submit">
                Log In
              </Button>
              <Link to="/signup">
                <Button
                  type="primary"
                  className="float-right w-40 rounded-md"
                  size="large"
                  htmlType="submit">
                  Sign up
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </Spin>
      </div>
      <section className="pt-14">
        <div>
          {/* <div class="h-3 text-3xl text-left text-gray-600">“</div> */}
          <p className="text-sm text-left text-gray-600">
            <span className="flex">
              <div>
                "We've seen a huge increase in our domain health (supporting 85-120% quota
                attainment). Our prior deliverability tool wasn't cutting it. Allegrow can stop
                risky emails being sent - other tools don't do that."
              </div>
              {/* <div class="h-3 text-3xl text-right text-gray-600 align-text-bottom mt-16">”</div> */}
            </span>
          </p>
        </div>
        <p className="pt-3 text-3xl text-right flex-col flex pr-3">
          <span className="text-base text-gray-600 text-bold">Keegan Otter</span>
          <span className="text-sm text-gray-600">Head of Revenue at Warmly</span>
        </p>
      </section>
    </>
  );
};

ReactDOM.render(
  <>
    <meta
      name="description"
      content="Allegrow application log-in page, you may enter your existing credentials or sign-up below to create an account."
    />
  </>,
  document.getElementById('metaDescription'),
);

export default LoginScreen;
