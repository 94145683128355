import {
  MAILBOX_CONNECT_FAIL,
  MAILBOX_CONNECT_REQUEST,
  MAILBOX_CONNECT_SUCCESS,
  MAILBOX_DOMAIN_LISTS_REQUEST,
  MAILBOX_DOMAIN_LISTS_REQUEST_FAIL,
  MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS,
  MAILBOX_DOMAIN_STATISTICS_REQUEST,
  MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL,
  MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS,
  MAILBOX_ID_STATISTICS_REQUEST,
  MAILBOX_ID_STATISTICS_REQUEST_FAIL,
  MAILBOX_ID_STATISTICS_REQUEST_SUCCESS,
  MAILBOX_LISTS_LEADERBOARD_FAIL,
  MAILBOX_LISTS_LEADERBOARD_REQUEST,
  MAILBOX_LISTS_LEADERBOARD_SUCCESS,
  MAILBOX_UPDATE_FAIL,
  MAILBOX_UPDATE_REQUEST,
  MAILBOX_UPDATE_SUCCESS,
  MAILBOX_VALIDATE_FAIL,
  MAILBOX_VALIDATE_REQUEST,
  MAILBOX_VALIDATE_SUCCESS,
  TEAM_MAILBOXES_FAIL,
  TEAM_MAILBOXES_REQUEST,
  TEAM_MAILBOXES_SUCCESS,
} from '../Constants/maillboxConstants';

import AxiosApi from '../../config/axios-api';

const mailboxIdStatisticsAction = (mailboxId) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_ID_STATISTICS_REQUEST, payload: mailboxId });
    const { data } = await AxiosApi.get('analytics/user-mailbox-statistics/' + mailboxId);
    dispatch({ type: MAILBOX_ID_STATISTICS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_ID_STATISTICS_REQUEST_FAIL, payload: error.message });
  }
};

const mailboxDomainListsAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_DOMAIN_LISTS_REQUEST, payload: companyId });
    const { data } = await AxiosApi.get('mailboxes/list-company-mailboxes/' + companyId);
    dispatch({ type: MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_DOMAIN_LISTS_REQUEST_FAIL, payload: error.message });
  }
};

const mailboxListsLeaderboardAction = () => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_LISTS_LEADERBOARD_REQUEST, payload: null });
    const { data } = await AxiosApi.get('/get-admin-leaderboard-data');
    dispatch({ type: MAILBOX_LISTS_LEADERBOARD_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_LISTS_LEADERBOARD_FAIL, payload: error.message });
  }
};

const mailboxDomainStatisticsAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_DOMAIN_STATISTICS_REQUEST, payload: companyId });
    const { data } = await AxiosApi.get('analytics/company-domains-statistics/' + companyId);
    dispatch({ type: MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL, payload: error.message });
  }
};

const mailboxDetailsUpdateAction = (mailboxData) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_UPDATE_REQUEST, payload: mailboxData });
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.post('mailboxes/update-mailbox-details', mailboxData);
    dispatch({ type: MAILBOX_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MAILBOX_UPDATE_FAIL, payload: error.message || error.errorMessage });
    throw error.errorMessage;
  }
};

const mailboxConnectAction = (mailboxData) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_CONNECT_REQUEST, payload: mailboxData });
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.post('mailboxes/connect-new-mailbox', mailboxData);
    dispatch({ type: MAILBOX_CONNECT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MAILBOX_CONNECT_FAIL, payload: error });
    throw error.errorMessage;
  }
};

const mailboxValidateAction = (mailboxData) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_VALIDATE_REQUEST, payload: mailboxData });
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.post('mailboxes/validate-mailbox-credentials', mailboxData);
    dispatch({ type: MAILBOX_VALIDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MAILBOX_VALIDATE_FAIL, payload: error.message || error.errorMessage });
    throw error.errorMessage;
  }
};

const getTeamMailboxesAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_MAILBOXES_REQUEST, payload: companyId });
    const { data } = await AxiosApi.get('mailboxes/team-mailboxes/' + companyId);
    dispatch({ type: TEAM_MAILBOXES_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: TEAM_MAILBOXES_FAIL, payload: error.message });
  }
};

const reassignMailboxesAction = (userId, mailboxesForReassign) => async () => {
  try {
    const { data } = await AxiosApi.post('mailboxes/reassign-mailboxes', {
      userId,
      mailboxesForReassign,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export {
  mailboxIdStatisticsAction,
  mailboxDetailsUpdateAction,
  mailboxConnectAction,
  mailboxDomainListsAction,
  mailboxDomainStatisticsAction,
  mailboxValidateAction,
  mailboxListsLeaderboardAction,
  getTeamMailboxesAction,
  reassignMailboxesAction,
};
