export const THROTTLING_MAILBOXES_REQUEST = 'THROTTLING_MAILBOXES_REQUEST';
export const THROTTLING_MAILBOXES_REQUEST_SUCCESS = 'THROTTLING_MAILBOXES_REQUEST_SUCCESS';
export const THROTTLING_MAILBOXES_REQUEST_FAIL = 'THROTTLING_MAILBOXES_REQUEST_FAIL';

export const THROTTLING_MAILBOX_DATA_REQUEST = 'THROTTLING_MAILBOX_DATA_REQUEST';
export const THROTTLING_MAILBOX_DATA_REQUEST_SUCCESS = 'THROTTLING_MAILBOX_DATA_REQUEST_SUCCESS';
export const THROTTLING_MAILBOX_DATA_REQUEST_FAIL = 'THROTTLING_MAILBOX_DATA_REQUEST_FAIL';

export const UPDATE_MAILBOX_LIMIT_REQUEST = 'UPDATE_MAILBOX_LIMIT_REQUEST';
export const UPDATE_MAILBOX_LIMIT_REQUEST_SUCCESS = 'UPDATE_MAILBOX_LIMIT_REQUEST_SUCCESS';
export const UPDATE_MAILBOX_LIMIT_REQUEST_FAIL = 'UPDATE_MAILBOX_LIMIT_REQUEST_FAIL';

export const UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST =
  'UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST';
export const UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_SUCCESS =
  'UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_SUCCESS';
export const UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_FAIL =
  'UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_FAIL';

export const UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST =
  'UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST';
export const UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_SUCCESS =
  'UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_SUCCESS';
export const UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_FAIL =
  'UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_FAIL';

export const UPDATE_THROTTLING_STATUS_REQUEST = 'UPDATE_THROTTLING_STATUS_REQUEST';
export const UPDATE_THROTTLING_STATUS_REQUEST_SUCCESS = 'UPDATE_THROTTLING_STATUS_REQUEST_SUCCESS';
export const UPDATE_THROTTLING_STATUS_REQUEST_FAIL = 'UPDATE_THROTTLING_STATUS_REQUEST_FAIL';

export const UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST =
  'UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST';
export const UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_SUCCESS =
  'UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_SUCCESS';
export const UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_FAIL =
  'UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_FAIL';
