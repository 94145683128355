const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
};

const chartJsTooltipRemove = () => {
  const chartJsObj = document.querySelector("#chartjs-tooltip");
  chartJsObj && (chartJsObj.remove());
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

export { scrollToTop, chartJsTooltipRemove, encodeQueryData }