import axios from 'axios';
import {Auth } from 'aws-amplify';

const AxiosApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});


AxiosApi.interceptors.request.use(config => {
  let demoData = localStorage.getItem("demoData")
  if(demoData)
    config.url = config.url + (config.url.includes("?")?"&":"?") +'demo-data=true'
  

  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (session) => {
        var idTokenExpire = session.getIdToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          await Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                    window.location.href = "/logout";
                } else {
                  config.headers.Authorization = "Bearer " + data.getIdToken().getJwtToken();
                  resolve(config);
                }
              });
            });
        } else {
          config.headers.Authorization = "Bearer " + session.getIdToken().getJwtToken();
          resolve(config);
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config);
      });
  });

});

AxiosApi.interceptors.response.use(
  response => response,
  error => {
    // We really want to throw the error so it is handled and we
    // don't get an unhandledrejection error. By throwing here, we
    // are handling the rejection, and bubbling up to the closest
    // error handler (try/catch or catch method call on a promise).
    const errorsMsg = ['The incoming token has expired','Unauthorized']
    if(errorsMsg.includes(error?.response?.data?.message)){
      window.location.href = "/logout";
    }
    throw error.response.data
  }
)

export default AxiosApi 