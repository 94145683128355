import {
  CLOSE_API_REQUEST,
  CLOSE_API_REQUEST_FAIL,
  CLOSE_API_REQUEST_SUCCESS,
  CONNECT_CLOSE_API_REQUEST,
  CONNECT_CLOSE_API_REQUEST_FAIL,
  CONNECT_CLOSE_API_REQUEST_SUCCESS,
  CONNECT_OUTREACH_API_REQUEST,
  CONNECT_OUTREACH_API_REQUEST_FAIL,
  CONNECT_OUTREACH_API_REQUEST_SUCCESS,
  CONNECT_SALESLOFT_API_REQUEST,
  CONNECT_SALESLOFT_API_REQUEST_FAIL,
  CONNECT_SALESLOFT_API_REQUEST_SUCCESS,
  DISCONNECT_CLOSE_API_REQUEST,
  DISCONNECT_CLOSE_API_REQUEST_FAIL,
  DISCONNECT_CLOSE_API_REQUEST_SUCCESS,
  DISCONNECT_OUTREACH_API_REQUEST,
  DISCONNECT_OUTREACH_API_REQUEST_FAIL,
  DISCONNECT_OUTREACH_API_REQUEST_SUCCESS,
  DISCONNECT_SALESLOFT_API_REQUEST,
  DISCONNECT_SALESLOFT_API_REQUEST_FAIL,
  DISCONNECT_SALESLOFT_API_REQUEST_SUCCESS,
  GET_CADENCES_FOR_USER_REQUEST,
  GET_CADENCES_FOR_USER_REQUEST_FAIL,
  GET_CADENCES_FOR_USER_REQUEST_SUCCESS,
  GET_CLOSE_INFO_API_REQUEST,
  GET_CLOSE_INFO_API_REQUEST_FAIL,
  GET_CLOSE_INFO_API_REQUEST_SUCCESS,
  GET_CLOSE_MAILBOXES_API_REQUEST,
  GET_CLOSE_MAILBOXES_API_REQUEST_FAIL,
  GET_CLOSE_MAILBOXES_API_REQUEST_SUCCESS,
  GET_CUSTOM_FIELDS_INFO_REQUEST,
  GET_CUSTOM_FIELDS_INFO_REQUEST_FAIL,
  GET_CUSTOM_FIELDS_INFO_REQUEST_SUCCESS,
  GET_INTEGRATION_DETAILS_API_REQUEST,
  GET_INTEGRATION_DETAILS_API_REQUEST_FAIL,
  GET_INTEGRATION_DETAILS_API_REQUEST_SUCCESS,
  GET_OUTREACH_INFO_API_REQUEST,
  GET_OUTREACH_INFO_API_REQUEST_FAIL,
  GET_OUTREACH_INFO_API_REQUEST_SUCCESS,
  GET_OUTREACH_MAILBOXES_API_REQUEST,
  GET_OUTREACH_MAILBOXES_API_REQUEST_FAIL,
  GET_OUTREACH_MAILBOXES_API_REQUEST_SUCCESS,
  GET_SALESLOFT_INFO_API_REQUEST,
  GET_SALESLOFT_INFO_API_REQUEST_FAIL,
  GET_SALESLOFT_INFO_API_REQUEST_SUCCESS,
  GET_SALESLOFT_MAILBOXES_API_REQUEST,
  GET_SALESLOFT_MAILBOXES_API_REQUEST_FAIL,
  GET_SALESLOFT_MAILBOXES_API_REQUEST_SUCCESS,
  UPDATE_CADENCE_STATUS_API_REQUEST,
  UPDATE_CADENCE_STATUS_API_REQUEST_FAIL,
  UPDATE_CADENCE_STATUS_API_REQUEST_SUCCESS,
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CUSTOM_FIELDS_REQUEST_FAIL,
  UPDATE_CUSTOM_FIELDS_REQUEST_SUCCESS,
  UPDATE_MAILBOX_STATUS_API_REQUEST,
  UPDATE_MAILBOX_STATUS_API_REQUEST_FAIL,
  UPDATE_MAILBOX_STATUS_API_REQUEST_SUCCESS,
  USER_MAILBOX_RECOMMEND,
  USER_MAILBOX_RECOMMEND_FAIL,
  USER_MAILBOX_RECOMMEND_SUCCESS,
} from '../Constants/integrationConstants';

import AxiosApi from '../../config/axios-api';

export const closeApiAction = (postData) => async (dispatch) => {
  try {
    dispatch({ type: CLOSE_API_REQUEST, payload: null });
    const { data } = await AxiosApi.post('integrations/closeio/', postData);
    dispatch({ type: CLOSE_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CLOSE_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getCloseApiInformation = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_CLOSE_INFO_API_REQUEST, payload: null });
    const { data } = await AxiosApi.get('integrations/close-oauth/', payload);
    dispatch({ type: GET_CLOSE_INFO_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_CLOSE_INFO_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const connectCloseApiAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONNECT_CLOSE_API_REQUEST, payload: null });
    const { data } = await AxiosApi.post('integrations/close-oauth/', payload); // changed from closeio to close-oauth
    dispatch({ type: CONNECT_CLOSE_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CONNECT_CLOSE_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const disconnectCloseApiAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DISCONNECT_CLOSE_API_REQUEST, payload: null });
    const { data } = await AxiosApi.delete('integrations/close-oauth/', payload); // changed from closeio to close-oauth
    dispatch({ type: DISCONNECT_CLOSE_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: DISCONNECT_CLOSE_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getCloseMailboxes = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_CLOSE_MAILBOXES_API_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/close-connected-mailboxes', payload);
    const formattedData = data.result.data.map((item) => {
      return { ...item, key: item.id };
    });
    dispatch({ type: GET_CLOSE_MAILBOXES_API_REQUEST_SUCCESS, payload: formattedData });
  } catch (error) {
    dispatch({ type: GET_CLOSE_MAILBOXES_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getOutreachApiInformation = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_OUTREACH_INFO_API_REQUEST, payload: null });
    const { data } = await AxiosApi.get('integrations/outreach/', payload);

    dispatch({ type: GET_OUTREACH_INFO_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_OUTREACH_INFO_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const connectOutreachApiAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONNECT_OUTREACH_API_REQUEST, payload: null });
    const { data } = await AxiosApi.post('integrations/outreach/', payload);
    dispatch({ type: CONNECT_OUTREACH_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CONNECT_OUTREACH_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const reauthOutreachApiAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONNECT_OUTREACH_API_REQUEST, payload: null });
    const { data } = await AxiosApi.post('integrations/outreach/reauth', payload);
    dispatch({ type: CONNECT_OUTREACH_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CONNECT_OUTREACH_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const disconnectOutreachApiAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DISCONNECT_OUTREACH_API_REQUEST, payload: null });
    const { data } = await AxiosApi.delete('integrations/outreach/', payload);
    dispatch({ type: DISCONNECT_OUTREACH_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: DISCONNECT_OUTREACH_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getOutreachMailboxes = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_OUTREACH_MAILBOXES_API_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/outreach-connected-mailboxes', payload);
    const formattedData = data.result.data.map((item) => {
      return { ...item, key: item.id };
    });
    dispatch({ type: GET_OUTREACH_MAILBOXES_API_REQUEST_SUCCESS, payload: formattedData });
  } catch (error) {
    dispatch({ type: GET_OUTREACH_MAILBOXES_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const updateMailboxStatus = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MAILBOX_STATUS_API_REQUEST, payload: null });
    const { data } = await AxiosApi.post('safety-net/set-mailbox-status', payload);
    dispatch({ type: UPDATE_MAILBOX_STATUS_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: UPDATE_MAILBOX_STATUS_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const updateCadenceStatus = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CADENCE_STATUS_API_REQUEST, payload: null });
    const { data } = await AxiosApi.post('safety-net/set-cadence-status', payload);
    dispatch({ type: UPDATE_CADENCE_STATUS_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: UPDATE_CADENCE_STATUS_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const userMailboxRecommendAction = () => async (dispatch) => {
  try {
    dispatch({ type: USER_MAILBOX_RECOMMEND, payload: null });
    const { data } = await AxiosApi.get('user-mailbox-recommend');
    dispatch({ type: USER_MAILBOX_RECOMMEND_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: USER_MAILBOX_RECOMMEND_FAIL, payload: error?.errorMessage });
  }
};

export const connectSalesloftApiAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONNECT_SALESLOFT_API_REQUEST, payload: null });
    const { data } = await AxiosApi.post('integrations/salesloft/', payload);
    dispatch({ type: CONNECT_SALESLOFT_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CONNECT_SALESLOFT_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const disconnectSalesloftApiAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DISCONNECT_SALESLOFT_API_REQUEST, payload: null });
    const { data } = await AxiosApi.delete('integrations/salesloft/', payload);
    dispatch({ type: DISCONNECT_SALESLOFT_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: DISCONNECT_SALESLOFT_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getSalesloftMailboxes = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_SALESLOFT_MAILBOXES_API_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/salesloft-connected-mailboxes', payload);
    const formattedData = data.result.data.map((item) => {
      return { ...item, key: item.id };
    });
    dispatch({ type: GET_SALESLOFT_MAILBOXES_API_REQUEST_SUCCESS, payload: formattedData });
  } catch (error) {
    dispatch({ type: GET_SALESLOFT_MAILBOXES_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getSalesloftApiInformation = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_SALESLOFT_INFO_API_REQUEST, payload: null });
    const { data } = await AxiosApi.get('integrations/salesloft/', payload);

    dispatch({ type: GET_SALESLOFT_INFO_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_SALESLOFT_INFO_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getCadencesForUser = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_CADENCES_FOR_USER_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/cadences', {
      params: {
        service: payload.service,
      },
    });

    dispatch({ type: GET_CADENCES_FOR_USER_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_CADENCES_FOR_USER_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getIntegrationDetails = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_INTEGRATION_DETAILS_API_REQUEST, payload: null });
    const { data } = await AxiosApi.get('integrations/details');
    dispatch({ type: GET_INTEGRATION_DETAILS_API_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_INTEGRATION_DETAILS_API_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getCustomFieldsInfo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_CUSTOM_FIELDS_INFO_REQUEST, payload: null });
    const { data } = await AxiosApi.get('safety-net/custom-fields', {
      params: {
        service: payload.service,
      },
    });
    dispatch({ type: GET_CUSTOM_FIELDS_INFO_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_CUSTOM_FIELDS_INFO_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const updateCustomFields = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTOM_FIELDS_REQUEST, payload: null });
    const { data } = await AxiosApi.post('safety-net/update-custom-fields', payload);
    dispatch({ type: UPDATE_CUSTOM_FIELDS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: UPDATE_CUSTOM_FIELDS_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const updateCadenceTypesAction = async (cadenceTypes) => {
  try {
    const requestBody = {
      cadenceTypes,
    };
    await AxiosApi.post('safety-net/update-cadence-types', requestBody);
  } catch (error) {
    console.error('Error in updating cadence types:', error);
    throw error;
  }
};
