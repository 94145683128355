import {
  THROTTLING_MAILBOXES_REQUEST,
  THROTTLING_MAILBOXES_REQUEST_FAIL,
  THROTTLING_MAILBOXES_REQUEST_SUCCESS,
  THROTTLING_MAILBOX_DATA_REQUEST,
  THROTTLING_MAILBOX_DATA_REQUEST_FAIL,
  THROTTLING_MAILBOX_DATA_REQUEST_SUCCESS,
  UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST,
  UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_FAIL,
  UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_SUCCESS,
  UPDATE_MAILBOX_LIMIT_REQUEST,
  UPDATE_MAILBOX_LIMIT_REQUEST_FAIL,
  UPDATE_MAILBOX_LIMIT_REQUEST_SUCCESS,
  UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST,
  UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_FAIL,
  UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_SUCCESS,
  UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST,
  UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_FAIL,
  UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_SUCCESS,
  UPDATE_THROTTLING_STATUS_REQUEST,
  UPDATE_THROTTLING_STATUS_REQUEST_FAIL,
  UPDATE_THROTTLING_STATUS_REQUEST_SUCCESS,
} from '../Constants/throttlingConstants';

import AxiosApi from '../../config/axios-api';

export const getThrottlingMailboxes = (payload) => async (dispatch) => {
  try {
    dispatch({ type: THROTTLING_MAILBOXES_REQUEST, payload: null });
    const { data } = await AxiosApi.get('throttling/get-mailboxes');
    const formattedData = data.result.map((item) => {
      return { ...item, key: item.mailboxId };
    });
    dispatch({ type: THROTTLING_MAILBOXES_REQUEST_SUCCESS, payload: formattedData });
  } catch (error) {
    dispatch({ type: THROTTLING_MAILBOXES_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const getMailboxThrottlingData = (mailboxId) => async (dispatch) => {
  try {
    dispatch({ type: THROTTLING_MAILBOX_DATA_REQUEST, payload: null });
    const { data } = await AxiosApi.get(`throttling/get-mailbox-data/${mailboxId}`);
    const formattedData = {
      ...data.result,
      dailySendingLimits: data.result.dailySendingLimits.map((item, index) => {
        return { ...item, key: item.mailboxId };
      }),
    };
    dispatch({ type: THROTTLING_MAILBOX_DATA_REQUEST_SUCCESS, payload: formattedData });
  } catch (error) {
    dispatch({ type: THROTTLING_MAILBOX_DATA_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const updateDailySendingLimit =
  (mailbox_id, daily_limit, service, date, email) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_MAILBOX_LIMIT_REQUEST, payload: null });
      const payload = { mailbox_id, daily_limit, service, date, email };
      await AxiosApi.post(`throttling/adjust-daily-limit`, payload);
      dispatch({ type: UPDATE_MAILBOX_LIMIT_REQUEST_SUCCESS, payload: null });
    } catch (error) {
      dispatch({ type: UPDATE_MAILBOX_LIMIT_REQUEST_FAIL, payload: error?.errorMessage });
    }
  };

export const updateMailboxThrottlingDetails = (mailboxDetails) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST, payload: null });
    const { data } = await AxiosApi.post(`throttling/update-sending-pattern`, mailboxDetails);
    dispatch({ type: UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_MAILBOX_THROTTLING_DETAILS_REQUEST_FAIL,
      payload: error?.errorMessage,
    });
  }
};

export const updateAllMailboxThrottlingDetails = (mailboxUpdateDetails) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST, payload: null });
    const { data } = await AxiosApi.post(
      `throttling/update-all-sending-patterns`,
      mailboxUpdateDetails,
    );
    dispatch({ type: UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_ALL_MAILBOXES_THROTTLING_DETAILS_REQUEST_FAIL,
      payload: error?.errorMessage,
    });
  }
};

export const updateThrottlingStatus = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_THROTTLING_STATUS_REQUEST, payload: null });
    const { data } = await AxiosApi.post(`throttling/update-throttling-status`, payload);
    dispatch({ type: UPDATE_THROTTLING_STATUS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_THROTTLING_STATUS_REQUEST_FAIL, payload: error?.errorMessage });
  }
};

export const updateThrottlingStatusForAllMailboxes = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST, payload: null });
    const { data } = await AxiosApi.post(
      `throttling/update-throttling-status-all-mailboxes`,
      payload,
    );
    dispatch({ type: UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_THROTTLING_ALL_MAILBOXES_STATUS_REQUEST_FAIL,
      payload: error?.errorMessage,
    });
  }
};
