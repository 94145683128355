import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ForgotPasswordModal = ({
  setForgotEmail,
  setVisible,
  setVisibleReset,
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsConfirmationVisible(false);
      form.resetFields(); // Clear the form fields when the modal is opened
    }
  }, [visible]);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 23,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 23,
    },
  };

  const onFinish = (values) => {
    setIsConfirmationVisible(true);

    let email = values.email.toLowerCase();
    setForgotEmail(email);

    Auth.forgotPassword(email)
      .then(() => {
        setVisibleReset(true);
      })
      .catch((error) => {});
  };

  const modalHeight = {
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
  };

  const renderFormContent = () => (
    <div style={modalHeight}>
      <div>
        <h2 className="pb-1 pt-1 text-3xl">Forgot Password</h2>
        <p className="please-type-in-your pb-2 pt-1">
          Please type your email and if we have an account with that address, we will send you the
          instructions for how to reset your password.
        </p>
        <Form
          form={form}
          {...layout}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          layout="vertical">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}>
            <Input placeholder="Email" className="float-left rounded-md" />
          </Form.Item>
        </Form>
      </div>
      <div>
        <Button
          type="primary"
          className="rounded-md mt-2"
          size="large"
          htmlType="submit"
          onClick={() => form.submit()}
          block>
          Reset My Password
        </Button>
      </div>
    </div>
  );

  const renderConfirmationContent = () => (
    <div style={modalHeight}>
      <div>
        <h2 className="pb-1 pt-1 text-3xl">Password Reset</h2>
        <p className="please-type-in-your pb-2 pt-1">
          If an account exists, an email will be sent to reset your password.
        </p>
      </div>
      <div>
        <Button
          type="primary"
          className="rounded-md mt-2"
          size="large"
          block
          onClick={() => setVisible(false)}>
          Close
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      className="forgot-password-modal ns-rounded-lg"
      visible={visible}
      title=""
      icon={<ExclamationCircleOutlined />}
      onCancel={onCancel}
      width={340}
      footer={null}>
      {isConfirmationVisible ? renderConfirmationContent() : renderFormContent()}
    </Modal>
  );
};

export default ForgotPasswordModal;
