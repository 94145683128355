export const CLOSE_API_REQUEST = 'CLOSE_API_REQUEST';
export const CLOSE_API_REQUEST_SUCCESS = 'CLOSE_API_REQUEST_SUCCESS';
export const CLOSE_API_REQUEST_FAIL = 'CLOSE_API_REQUEST_FAIL';

export const GET_CLOSE_MAILBOXES_API_REQUEST = 'GET_CLOSE_MAILBOXES_API_REQUEST';
export const GET_CLOSE_MAILBOXES_API_REQUEST_SUCCESS = 'GET_CLOSE_MAILBOXES_API_REQUEST_SUCCESS';
export const GET_CLOSE_MAILBOXES_API_REQUEST_FAIL = 'GET_CLOSE_MAILBOXES_API_REQUEST_FAIL';

export const GET_CLOSE_INFO_API_REQUEST = 'GET_CLOSE_INFO_API_REQUEST';
export const GET_CLOSE_INFO_API_REQUEST_SUCCESS = 'GET_CLOSE_INFO_API_REQUEST_SUCCESS';
export const GET_CLOSE_INFO_API_REQUEST_FAIL = 'GET_CLOSE_INFO_API_REQUEST_FAIL';

export const CONNECT_CLOSE_API_REQUEST = 'CONNECT_CLOSE_API_REQUEST';
export const CONNECT_CLOSE_API_REQUEST_SUCCESS = 'CONNECT_CLOSE_API_REQUEST_SUCCESS';
export const CONNECT_CLOSE_API_REQUEST_FAIL = 'CONNECT_CLOSE_API_REQUEST_FAIL';

export const DISCONNECT_CLOSE_API_REQUEST = 'DISCONNECT_CLOSE_API_REQUEST';
export const DISCONNECT_CLOSE_API_REQUEST_SUCCESS = 'DISCONNECT_CLOSE_API_REQUEST_SUCCESS';
export const DISCONNECT_CLOSE_API_REQUEST_FAIL = 'DISCONNECT_CLOSE_API_REQUEST_FAIL';

export const UPDATE_MAILBOX_STATUS_API_REQUEST = 'UPDATE_MAILBOX_STATUS_API_REQUEST';
export const UPDATE_MAILBOX_STATUS_API_REQUEST_SUCCESS =
  'UPDATE_MAILBOX_STATUS_API_REQUEST_SUCCESS';
export const UPDATE_MAILBOX_STATUS_API_REQUEST_FAIL = 'UPDATE_MAILBOX_STATUS_API_REQUEST_FAIL';

export const GET_OUTREACH_INFO_API_REQUEST = 'GET_OUTREACH_INFO_API_REQUEST';
export const GET_OUTREACH_INFO_API_REQUEST_SUCCESS = 'GET_OUTREACH_INFO_API_REQUEST_SUCCESS';
export const GET_OUTREACH_INFO_API_REQUEST_FAIL = 'GET_OUTREACH_INFO_API_REQUEST_FAIL';

export const CONNECT_OUTREACH_API_REQUEST = 'CONNECT_OUTREACH_API_REQUEST';
export const CONNECT_OUTREACH_API_REQUEST_SUCCESS = 'CONNECT_OUTREACH_API_REQUEST_SUCCESS';
export const CONNECT_OUTREACH_API_REQUEST_FAIL = 'CONNECT_OUTREACH_API_REQUEST_FAIL';

export const DISCONNECT_OUTREACH_API_REQUEST = 'DISCONNECT_OUTREACH_API_REQUEST';
export const DISCONNECT_OUTREACH_API_REQUEST_SUCCESS = 'DISCONNECT_OUTREACH_API_REQUEST_SUCCESS';
export const DISCONNECT_OUTREACH_API_REQUEST_FAIL = 'DISCONNECT_OUTREACH_API_REQUEST_FAIL';

export const GET_OUTREACH_MAILBOXES_API_REQUEST = 'GET_OUTREACH_MAILBOXES_API_REQUEST';
export const GET_OUTREACH_MAILBOXES_API_REQUEST_SUCCESS =
  'GET_OUTREACH_MAILBOXES_API_REQUEST_SUCCESS';
export const GET_OUTREACH_MAILBOXES_API_REQUEST_FAIL = 'GET_OUTREACH_MAILBOXES_API_REQUEST_FAIL';

export const USER_MAILBOX_RECOMMEND = 'USER_MAILBOX_RECOMMEND';
export const USER_MAILBOX_RECOMMEND_SUCCESS = 'USER_MAILBOX_RECOMMEND_SUCCESS';
export const USER_MAILBOX_RECOMMEND_FAIL = 'USER_MAILBOX_RECOMMEND_FAIL';

export const CONNECT_SALESLOFT_API_REQUEST = 'CONNECT_SALESLOFT_API_REQUEST';
export const CONNECT_SALESLOFT_API_REQUEST_SUCCESS = 'CONNECT_SALESLOFT_API_REQUEST_SUCCESS';
export const CONNECT_SALESLOFT_API_REQUEST_FAIL = 'CONNECT_SALESLOFT_API_REQUEST_FAIL';

export const DISCONNECT_SALESLOFT_API_REQUEST = 'DISCONNECT_SALESLOFT_API_REQUEST';
export const DISCONNECT_SALESLOFT_API_REQUEST_SUCCESS = 'DISCONNECT_SALESLOFT_API_REQUEST_SUCCESS';
export const DISCONNECT_SALESLOFT_API_REQUEST_FAIL = 'DISCONNECT_SALESLOFT_API_REQUEST_FAIL';

export const GET_SALESLOFT_MAILBOXES_API_REQUEST = 'GET_SALESLOFT_MAILBOXES_API_REQUEST';
export const GET_SALESLOFT_MAILBOXES_API_REQUEST_SUCCESS =
  'GET_SALESLOFT_MAILBOXES_API_REQUEST_SUCCESS';
export const GET_SALESLOFT_MAILBOXES_API_REQUEST_FAIL = 'GET_SALESLOFT_MAILBOXES_API_REQUEST_FAIL';

export const GET_SALESLOFT_INFO_API_REQUEST = 'GET_SALESLOFT_INFO_API_REQUEST';
export const GET_SALESLOFT_INFO_API_REQUEST_SUCCESS = 'GET_SALESLOFT_INFO_API_REQUEST_SUCCESS';
export const GET_SALESLOFT_INFO_API_REQUEST_FAIL = 'GET_SALESLOFT_INFO_API_REQUEST_FAIL';

export const GET_CADENCES_FOR_USER_REQUEST = 'GET_CADENCES_FOR_USER_REQUEST';
export const GET_CADENCES_FOR_USER_REQUEST_SUCCESS = 'GET_CADENCES_FOR_USER_REQUEST_SUCCESS';
export const GET_CADENCES_FOR_USER_REQUEST_FAIL = 'GET_CADENCES_FOR_USER_REQUEST_FAIL';

export const UPDATE_CADENCE_STATUS_API_REQUEST = 'UPDATE_CADENCE_STATUS_API_REQUEST';
export const UPDATE_CADENCE_STATUS_API_REQUEST_SUCCESS =
  'UPDATE_CADENCE_STATUS_API_REQUEST_SUCCESS';
export const UPDATE_CADENCE_STATUS_API_REQUEST_FAIL = 'UPDATE_CADENCE_STATUS_API_REQUEST_FAIL';

export const GET_INTEGRATION_DETAILS_API_REQUEST = 'GET_INTEGRATION_DETAILS_API_REQUEST';
export const GET_INTEGRATION_DETAILS_API_REQUEST_SUCCESS =
  'GET_INTEGRATION_DETAILS_API_REQUEST_SUCCESS';
export const GET_INTEGRATION_DETAILS_API_REQUEST_FAIL = 'GET_INTEGRATION_DETAILS_API_REQUEST_FAIL';

export const GET_CUSTOM_FIELDS_INFO_REQUEST = 'GET_CUSTOM_FIELDS_INFO_REQUEST';
export const GET_CUSTOM_FIELDS_INFO_REQUEST_SUCCESS = 'GET_CUSTOM_FIELDS_INFO_REQUEST_SUCCESS';
export const GET_CUSTOM_FIELDS_INFO_REQUEST_FAIL = 'GET_CUSTOM_FIELDS_INFO_REQUEST_FAIL';

export const UPDATE_CUSTOM_FIELDS_REQUEST = 'UPDATE_CUSTOM_FIELDS_REQUEST';
export const UPDATE_CUSTOM_FIELDS_REQUEST_SUCCESS = 'UPDATE_CUSTOM_FIELDS_REQUEST_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_REQUEST_FAIL = 'UPDATE_CUSTOM_FIELDS_REQUEST_FAIL';

export const UPDATE_CUSTOM_FIELDS_LOCALLY = 'UPDATE_CUSTOM_FIELDS_LOCALLY';
