import {
  THROTTLING_MAILBOXES_REQUEST,
  THROTTLING_MAILBOXES_REQUEST_FAIL,
  THROTTLING_MAILBOXES_REQUEST_SUCCESS,
  THROTTLING_MAILBOX_DATA_REQUEST,
  THROTTLING_MAILBOX_DATA_REQUEST_FAIL,
  THROTTLING_MAILBOX_DATA_REQUEST_SUCCESS,
} from '../Constants/throttlingConstants';

export function getThrottlingMailboxesReducer(
  state = { throttlingMailboxesApiData: null },
  action,
) {
  switch (action.type) {
    case THROTTLING_MAILBOXES_REQUEST:
      return { loading: true, throttlingMailboxesApiData: null };
    case THROTTLING_MAILBOXES_REQUEST_SUCCESS:
      return { loading: false, throttlingMailboxesApiData: action.payload };
    case THROTTLING_MAILBOXES_REQUEST_FAIL:
      return { loading: false, throttlingMailboxesApiError: action.payload };
    default:
      return state;
  }
}

export function getMailboxThrottlingDataReducer(
  state = { mailboxThrottlingDataApiData: null },
  action,
) {
  switch (action.type) {
    case THROTTLING_MAILBOX_DATA_REQUEST:
      return { loading: true, mailboxThrottlingDataApiData: null };
    case THROTTLING_MAILBOX_DATA_REQUEST_SUCCESS:
      return { loading: false, mailboxThrottlingDataApiData: action.payload };
    case THROTTLING_MAILBOX_DATA_REQUEST_FAIL:
      return { loading: false, mailboxThrottlingDataApiError: action.payload };
    default:
      return state;
  }
}
