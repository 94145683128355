import {
  ADD_RECENT_RECORD,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST,
  SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST,
  SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_JOBS_REQUEST,
  SAFETY_NET_EVERYWHERE_JOBS_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_JOBS_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST,
  SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_SUCCESS,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_FAIL,
  SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_SUCCESS,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS,
} from '../Constants/safetyNetConstants';

import { initial } from 'lodash';

const initialSafetyNetEverywhereStatAndDataState = {
  loading: false,
  error: null,
  data: {
    allStatsAndRecords: {},
    mostRecentStatsAndRecords: {
      recentRecords: [],
      statusCounts: [],
    },
    notExportedStatsAndRecords: {
      recentRecords: [],
      statusCounts: [],
    },
  },
};

function safetyNetMailboxChartDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetMailboxTimelineDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetAllMailboxesChartDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetAllMailboxesTimelineDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetEverywhereJobsReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_EVERYWHERE_JOBS_REQUEST:
      return { ...state, loading: true };
    case SAFETY_NET_EVERYWHERE_JOBS_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_EVERYWHERE_JOBS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetEverywhereFileDataReducer(state = { data: {}, loading: false }, action) {
  switch (action.type) {
    case SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST:
      return { ...state, loading: true, error: null };
    case SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_SUCCESS:
      const { filename, fileData } = action.payload;
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [filename]: fileData,
        },
        error: null,
      };
    case SAFETY_NET_EVERYWHERE_CSV_FILE_REQUEST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetEverywhereStatsAndDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST:
      return { ...state, loading: true };
    case SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_EVERYWHERE_STATS_AND_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetEverywhereValidationsByStatusReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_EVERYWHERE_VALIDATIONS_BY_STATUS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetEverywhereValidationsForJobReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_EVERYWHERE_VALIDATIONS_FOR_JOB_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetEverywhereCreditsDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST:
      return { ...state, loading: true };
    case SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_EVERYWHERE_CREDITS_DATA_REQUEST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  safetyNetMailboxChartDataReducer,
  safetyNetMailboxTimelineDataReducer,
  safetyNetAllMailboxesChartDataReducer,
  safetyNetAllMailboxesTimelineDataReducer,
  safetyNetEverywhereJobsReducer,
  safetyNetEverywhereFileDataReducer,
  safetyNetEverywhereStatsAndDataReducer,
  safetyNetEverywhereValidationsByStatusReducer,
  safetyNetEverywhereValidationsForJobReducer,
  safetyNetEverywhereCreditsDataReducer,
};
