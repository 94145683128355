import {
  USER_CONTENT_TESTS,
  USER_CREATE_CONTENT_TESTS,
  USER_CREATE_CONTENT_TESTS_ID,
  USER_EDIT_CONTENT_TESTS_ID,
  USER_EDIT_MAILBOX_ID,
  USER_INSIGHTS,
  USER_LEADERBOARD,
  USER_MAILBOXES,
  USER_MAILBOX_SAFETY_NET,
  USER_MAILBOX_THROTTLING,
  USER_PROFILE,
  USER_SAFETY_NET,
  USER_SAFETY_NET_EVERYWHERE,
  USER_SAFETY_NET_EVERYWHERE_FILTER,
  USER_SECURITY,
  USER_SENDING_PATTERN_ID,
  USER_SETTINGS,
  USER_SETTINGS_COMPANY,
  USER_SETTINGS_INTEGRATIONS,
  USER_SETTINGS_MY_PROFILE,
  USER_SETTINGS_SECURITY,
  USER_SETTINGS_SUBSCRIPTION,
  USER_SETTINGS_TEAM_DETAILS,
  USER_SUBSCRIPTION,
  USER_TEAM,
  USER_THROTTLING,
} from '../Routes/Constants/RouteUrls';

import React from 'react';

const MailboxScreen = React.lazy(() => import('../Screens/MailboxScreen'));
const EditMailBoxScreen = React.lazy(() => import('../Screens/EditMailBoxScreen'));
const SendingPatternScreen = React.lazy(() =>
  import('../Screens/SendingPattern/SendingPatternScreen'),
);
const ContentTestsScreen = React.lazy(() => import('../Screens/ContentTestsScreen'));
const EditContentTestsScreen = React.lazy(() => import('../Screens/EditContentTestsScreen'));
const CreateContentTestsScreen = React.lazy(() => import('../Screens/CreateContentTestsScreen'));
const InsightsScreen = React.lazy(() => import('../Screens/InsightsScreen'));
const LeaderboardScreen = React.lazy(() => import('../Screens/LeaderboardScreen'));
const SafetyNetScreen = React.lazy(() => import('../Screens/SafetyNetScreen'));
const SafetyNetMailboxScreen = React.lazy(() => import('../Screens/SafetyNetMailboxScreen'));
const SafetyNetEverywhereScreen = React.lazy(() => import('../Screens/SafetyNetEverywhereScreen'));
const ThrottlingScreen = React.lazy(() => import('../Screens/ThrottlingScreen'));
const ThrottlingMailboxScreen = React.lazy(() => import('../Screens/ThrottlingMailboxScreen'));
const SettingsScreen = React.lazy(() => import('../Screens/SettingsScreen'));
const MyProfileScreen = React.lazy(() => import('../Screens/MyProfileScreen'));
const SecurityScreen = React.lazy(() => import('../Screens/SecurityScreen'));
const SubscriptionScreen = React.lazy(() => import('../Screens/SubscriptionScreen'));
const TeamScreen = React.lazy(() => import('../Screens/TeamScreen'));
const PageNotFoundScreen = React.lazy(() => import('../Screens/PageNotFoundScreen'));

const ProtectedRoutes = [
  { path: USER_MAILBOXES, component: MailboxScreen, exact: true },
  { path: USER_EDIT_MAILBOX_ID, component: EditMailBoxScreen, exact: true },
  { path: USER_SENDING_PATTERN_ID, component: SendingPatternScreen, exact: true },
  { path: USER_CONTENT_TESTS, component: ContentTestsScreen, exact: true },
  { path: USER_EDIT_CONTENT_TESTS_ID, component: EditContentTestsScreen, exact: true },
  { path: USER_CREATE_CONTENT_TESTS, component: CreateContentTestsScreen, exact: true },
  { path: USER_CREATE_CONTENT_TESTS_ID, component: CreateContentTestsScreen, exact: true },
  { path: USER_INSIGHTS, component: InsightsScreen, exact: true },
  { path: USER_LEADERBOARD, component: LeaderboardScreen, exact: true },
  { path: USER_SAFETY_NET, component: SafetyNetScreen, exact: true },
  { path: USER_SAFETY_NET_EVERYWHERE, component: SafetyNetEverywhereScreen, exact: true },
  { path: USER_SAFETY_NET_EVERYWHERE_FILTER, component: SafetyNetEverywhereScreen, exact: true },
  { path: USER_THROTTLING, component: ThrottlingScreen, exact: true },
  { path: USER_MAILBOX_SAFETY_NET, component: SafetyNetMailboxScreen, exact: true },
  { path: USER_MAILBOX_THROTTLING, component: ThrottlingMailboxScreen, exact: true },
  { path: USER_PROFILE, component: MyProfileScreen },
  { path: USER_TEAM, component: TeamScreen },
  { path: USER_SECURITY, component: SecurityScreen },
  { path: USER_SUBSCRIPTION, component: SubscriptionScreen },
  { path: USER_SETTINGS, component: SettingsScreen, exact: true },
  { path: USER_SETTINGS_MY_PROFILE, component: SettingsScreen },
  { path: USER_SETTINGS_COMPANY, component: SettingsScreen },
  { path: USER_SETTINGS_SECURITY, component: SettingsScreen },
  { path: USER_SETTINGS_SUBSCRIPTION, component: SettingsScreen },
  { path: USER_SETTINGS_INTEGRATIONS, component: SettingsScreen },
  { path: USER_SETTINGS_TEAM_DETAILS, component: SettingsScreen },
  { path: '', component: PageNotFoundScreen },
];
export default ProtectedRoutes;
