import { Flex, Progress } from 'antd';

import React from 'react';

export default function SafetyNetEverywhereCreditsUsage({ safetyNetEverywhereCreditsData }) {
  const creditsRemaining = safetyNetEverywhereCreditsData?.creditsRemaining || 0;
  const monthlyCreditLimit = safetyNetEverywhereCreditsData?.monthlyCreditLimit || 0;

  const creditsUsed = monthlyCreditLimit - creditsRemaining;
  const percentageUsed = ((creditsUsed / monthlyCreditLimit) * 100).toFixed(0) || 0;

  return (
    <div className="mt-12">
      <h1 className="text-center text-lg font-bold">Credits</h1>
      <div className="flex justify-center p-4">
        <Progress
          type="circle"
          size="large"
          className="mt-4"
          percent={percentageUsed || 0}
          showInfo={true}
        />
      </div>
      <p className="p-4 text-center">{`Used: ${creditsUsed}`}</p>
      <p className="p-4 text-center">{`Remaining: ${creditsRemaining}`}</p>
    </div>
  );
}
