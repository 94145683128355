import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { GTM_NO_SCRIPT_TAG, GTM_SCRIPT_TAG } from '../Utility/Google/TagManager';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import AxiosApi from '../config/axios-api';
import ConfirmSignupMessageModal from '../Modals/ConfirmSignupMessageModal';
import ReactDOM from 'react-dom';
import { USER_MAILBOXES } from '../Routes/Constants/RouteUrls';
import { defaultOwnerPermissions } from '../Helpers/defaultPermissions';
import generateBlockedDomainsPattern from '../Constants/blockedDomains';
import { openNotificationError } from '../Helpers/globalNotification';
import parse from 'html-react-parser';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import { useDispatch } from 'react-redux';

const blockedDomainsPattern = generateBlockedDomainsPattern();

const SignupScreen = () => {
  const history = useHistory();
  const [loader, setloader] = useState(false);
  const [email, setEmail] = useState('');
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');
  const companyId = localStorage.getItem('companyId');
  if (userId && companyId) {
    history.push(USER_MAILBOXES);
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 23,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 23,
    },
  };

  const setSignupUser = async (sessionId, redirect) => {
    try {
      setloader(true);
      AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
      if (redirect) {
        let companyId = localStorage.getItem('companyId');
        await dispatch(planDetailsAction(companyId));
        history.push(USER_MAILBOXES);
      } else {
        setloader(false);
        setVisible(true);
      }
    } catch (e) {}
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    const redirect = urlParams.get('redirect');
    const refcode = urlParams.get('refcode');

    if (sessionId) {
      setSignupUser(sessionId, redirect);
    }
    if (process.env.REACT_APP_GOOGLE_TAG_ID) {
      ReactDOM.render(parse(GTM_SCRIPT_TAG), document.getElementById('head-gtm'));
      ReactDOM.render(parse(GTM_NO_SCRIPT_TAG), document.getElementById('root-gtm'));
    }

    return () => {
      if (process.env.REACT_APP_GOOGLE_TAG_ID) {
        ReactDOM.render('', document.getElementById('head-gtm'));
        ReactDOM.render('', document.getElementById('root-gtm'));
      }
    };
  }, []);

  const onFinish = async (values) => {
    setloader(true);
    setEmail(values.email);
    try {
      const email = values.email.toLowerCase();
      const objUser = {
        username: email,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        attributes: {
          email,
          'custom:first_name': values.first_name,
          'custom:last_name': values.last_name,
          'custom:role': 'owner',
          'custom:permissions': JSON.stringify(defaultOwnerPermissions),
        },
      };

      const signup = await Auth.signUp(objUser);
      AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
      let user = await AxiosApi.post(
        'auth/signup',
        JSON.stringify({ ...objUser, ...{ company_name: values.company_name, password: '' } }),
      ).catch((error) => {
        throw error.response.data;
      });
      process.env.REACT_APP_GOOGLE_TAG_ID && window.dataLayer.push({ event: 'accountSignup' });
      if (!signup.userConfirmed) {
        localStorage.setItem(
          'signupUserData',
          JSON.stringify({
            userId: user.data.result.userId,
            companyId: user.data.result.companyId,
            companyName: values.company_name,
          }),
        );
        localStorage.setItem('signupUserEmail', email);
        window.heap.resetIdentity();
        window.heap.identify(user.data.result.userId);
        window.heap.addUserProperties({
          'First Name': values.first_name,
          'Last Name': values.last_name,
          email,
          'Company Name': values.company_name,
          Referrer: 'neverspam',
        });
        setVisible(true);
        // setloader(false);
        history.push('/pricing');
      } else {
        await Auth.signIn(email, values.password);
        history.push(USER_MAILBOXES);
      }
    } catch (e) {
      setloader(false);
      if (e.errors) {
        e.message = e.errors.map((err, errIndex) => (
          <p key={errIndex}>{err.message.replaceAll('_', ' ')}</p>
        ));
        e.message = <div>{e.message}</div>;
      }
      openNotificationError({ msg: 'Signup Error', description: e.message });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <ConfirmSignupMessageModal visible={visible} onCancel={() => setVisible(false)} />
      <div className="w-46 pb-8">
        <h4 className="Welcome-Please-sign">Welcome please sign up below.</h4>
      </div>
      <div className="w-46 pb-12">
        <Spin spinning={loader}>
          <Form
            {...layout}
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your First Name!',
                },
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
              <Input placeholder="First Name" className="rounded-md" />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
              <Input placeholder="Last Name" className="rounded-md" />
            </Form.Item>
            <Form.Item
              name="company_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your company name!',
                },
              ]}>
              <Input placeholder="Company Name" className="float-left rounded-md" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                  type: 'email',
                },
                {
                  pattern: blockedDomainsPattern,
                  message: 'Please use your business email',
                },
              ]}>
              <Input placeholder="Email" className="float-left rounded-md" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\\^\\$\\*\\.\\[\\]\\{\\}\\(\\)\\?"!@#%&/\\\\,><\':;|_~`=+\\-]).{8,}$',
                  ),
                  message: 'Your password is too weak',
                },
              ]}>
              <Input.Password placeholder="Password" className="rounded-md" />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject('Please accept Terms of Use and the Privacy Policy'),
                },
              ]}
              {...tailLayout}>
              <Checkbox>
                I agree to the{' '}
                <a
                  className="text-blue-500"
                  href="https://www.neverspam.io/terms-and-conditions"
                  rel="noopener noreferrer"
                  target="_blank">
                  Terms of Use
                </a>{' '}
                and the{' '}
                <a
                  className="text-blue-500"
                  rel="noopener noreferrer"
                  href="https://www.neverspam.io/privacy-policy"
                  target="_blank">
                  Privacy Policy
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item className="" {...tailLayout}>
              <Button
                type="primary"
                className="float-left w-40 rounded-md"
                size="large"
                htmlType="submit">
                Sign up
              </Button>
              <Link to="/">
                <Button
                  className="float-right w-40 text-gray-500 rounded-md"
                  size="large"
                  htmlType="submit">
                  Log In
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default SignupScreen;
